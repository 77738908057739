
      @import "./assets/vue/styles/_vars.scss";
      @import "./assets/vue/styles/_mixins.scss";
      $color-primary: #765691;
      $color-secondary: #ffce00;
			$color-border: #e6dde9;
			$color-bg: #f8f7f8;
			$color-text: #16051a;
    

































































































































































































































































































































































































































































































































.fieldset.domain {
	&::before {
		content: 'https://';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		line-height: 34px;
		color: $color-gray;
		pointer-events: none;
	}

	input {
		padding-left: 45px;
	}
}

.domains-table {
  .row {
    grid-template-columns: 3fr 1fr;
  }
}
