input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    box-shadow: 0 0 0 30px $color-bg inset !important;
}



.fieldset {
  position: relative;
  margin-bottom: $spacer;

  &.fieldset-error {
    input {
      border-color: $color-error;
    }
  }
}

input[type=text],
input[type=password],
input[type=email],
input[type=number],
textarea {
  font-size: $fontsize;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  border-bottom: 2px solid $color-border;
  transition: color .3s, border .3s;
  background: $color-bg;
  border-radius: 0;
  height: 34px;
  appearance: none;
  text-align: inherit;
  font-weight: $regular;
  color: inherit;
  font-family: inherit;

  &:-webkit-autofill::first-line {
    font-family: inherit;
    font-size: $fontsize;
  }
}

input[type=radio] {
  display: inline-block;
}

// Remove Arrows on number
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: $color-primary;
}

::placeholder {
  color: $color-gray;
}

.fieldsetgroup {
  display: flex;

  .fieldset {
      margin-right: $spacer;
      flex: 1;

      &:last-child {
          margin-right: 0;
      }
  }
}

// PASSWORD INSTRUCTIONS
.password-instructions {
  margin-top: 10px;

  span {
    display: inline-block;
    font-size: 11px;
    background: $color-error;
    border-radius: 5px;
    padding: 2px 5px;
    margin-bottom: 5px;

    &:not(:first-child) {
      color: $color-bg;
    }

    &.text {
      background: transparent;
    }
  }
}

label:not(.switch) {
  font-size: $fontsize-sm;
  font-weight: $medium;
  line-height: 1;
}

// RADIO
.radio label,
.checkbox label {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  user-select: none;
  line-height: 18px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      border-color: lighten($color-primary, 25%);
    }

    &:checked ~ .checkmark:after {
      display: block;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border: 2px solid $color-border;
    border-radius: 100%;
    transition: all .3s;

    &:after {
      top: 4px;
      left: 4px;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background: $color-primary;
      content: "";
      position: absolute;
      display: none;
    }
  }

  &:hover input ~ .checkmark {
    border-color: $color-gray;
  }
} 

// SWITCH
.checkbox:not(:last-child) {
  margin-bottom: 10px;
}

label.switch {
  position: relative;
  display: block;
  width: 100%;
  height: 26px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 0;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 40px;
    background-color: transparent;
    transition: .4s;
    border-radius: 14px;
    border: 2px solid $color-border;

    &:before {
      position: absolute;
      content: '';
      height: 18px;
      width: 18px;
      left: 2px;
      bottom: 2px;
      background-color: $color-gray;
      transition: .4s;
      border-radius: 100%;
    }
  }
}

input:checked + .slider {
  border-color: $color-primary;
}

input:checked + .slider:before {
  background-color: $color-primary;
}

input:not(:checked):hover + .slider:before {
  transform: translateX(2px);
}

input:checked:hover + .slider:before {
  transform: translateX(12px);
}

input:checked + .slider:before {
  transform: translateX(14px);
}