
      @import "./assets/vue/styles/_vars.scss";
      @import "./assets/vue/styles/_mixins.scss";
      $color-primary: #765691;
      $color-secondary: #ffce00;
			$color-border: #e6dde9;
			$color-bg: #f8f7f8;
			$color-text: #16051a;
    













































































































































































































































































































































































































































































































































.cronjobs-table {
	.row {
		grid-template-columns: 2fr 3fr 80px;
	}
}
