
      @import "./assets/vue/styles/_vars.scss";
      @import "./assets/vue/styles/_mixins.scss";
      $color-primary: #765691;
      $color-secondary: #ffce00;
			$color-border: #e6dde9;
			$color-bg: #f8f7f8;
			$color-text: #16051a;
    




































































































































































































































































































































































































































































































































.software {
	display: grid;
	grid-gap: $spacer-sm;
	grid-template-columns: repeat(auto-fit,minmax(100px,1fr));

	.sw {
		cursor: pointer;
		font-weight: $medium;
		text-align: center;
		user-select: none;

		svg {
			width: 50px;
			display: block;
			height: auto;
			margin: 0 auto $spacer-sm auto;
			fill: lighten($color-primary, 25%);
		}

		&:hover {
			svg {
				fill: $color-primary;
			}
		}

		&.selected {
			background: $color-primary;
			color: $color-bg;

			svg {
				fill: $color-bg;
			}
		}
	}
}
