.container,
.container-full {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: $spacer;
  padding-left: $spacer;
  padding-top: $spacer;
  padding-bottom: $spacer;
}

@media (min-width: 768px) {
  .container,
  .container-full {
    padding-right: $spacer-lg;
    padding-left: $spacer-lg;
    padding-top: $spacer-lg;
    padding-bottom: $spacer-lg;
  }
}

@media (min-width: 1200px) {
  .container {
    padding-right: $spacer-xl;
    padding-left: $spacer-xl;
  }
}

// .container {
//   max-width: 1200px;
// }

.container .grid,
.container-full .grid {
  margin-right: -$spacer;
  margin-left: -$spacer;
}

.grid {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 auto;
  position: relative;
  *letter-spacing: normal;
  list-style-type: none;
}

.grid::before, .grid::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: normal;
  word-spacing: normal;
  white-space: normal;
}

[class*="col-"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: normal;
  word-spacing: normal;
  white-space: normal;
  position: relative;
  width: 100%;
  vertical-align: top;
  padding: $spacer;
  display: inline-block;
  *display: inline;
  zoom: 1;

  &.fieldset {
    padding-top: 0;
    padding-bottom: 0;
  }
}

[class*="col-"]::before, [class*="col-"]::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: normal;
  word-spacing: normal;
  white-space: normal;
}

[class*="col-"] .grid {
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: -$spacer;
}

.col-12 {
  width: 100%;
  *width: 99.9%;
}

.col-11 {
  width: 91.66666667%;
  *width: 91.56666667%;
}

.col-10 {
  width: 83.33333333%;
  *width: 83.23333333%;
}

.col-9 {
  width: 75%;
  *width: 74.9%;
}

.col-8 {
  width: 66.66666667%;
  *width: 66.56666667%;
}

.col-7 {
  width: 58.33333333%;
  *width: 58.23333333%;
}

.col-6 {
  width: 50%;
  *width: 49.9%;
}

.col-5 {
  width: 41.66666667%;
  *width: 41.56666667%;
}

.col-4 {
  width: 33.33333333%;
  *width: 33.23333333%;
}

.col-3 {
  width: 25%;
  *width: 24.9%;
}

.col-2 {
  width: 16.66666667%;
  *width: 16.56666667%;
}

.col-1 {
  width: 8.33333333%;
  *width: 8.23333333%;
}

@media (min-width: 576px) {
  .col-xs-12 {
    width: 100%;
    *width: 99.9%;
  }
  .col-xs-11 {
    width: 91.66666667%;
    *width: 91.56666667%;
  }
  .col-xs-10 {
    width: 83.33333333%;
    *width: 83.23333333%;
  }
  .col-xs-9 {
    width: 75%;
    *width: 74.9%;
  }
  .col-xs-8 {
    width: 66.66666667%;
    *width: 66.56666667%;
  }
  .col-xs-7 {
    width: 58.33333333%;
    *width: 58.23333333%;
  }
  .col-xs-6 {
    width: 50%;
    *width: 49.9%;
  }
  .col-xs-5 {
    width: 41.66666667%;
    *width: 41.56666667%;
  }
  .col-xs-4 {
    width: 33.33333333%;
    *width: 33.23333333%;
  }
  .col-xs-3 {
    width: 25%;
    *width: 24.9%;
  }
  .col-xs-2 {
    width: 16.66666667%;
    *width: 16.56666667%;
  }
  .col-xs-1 {
    width: 8.33333333%;
    *width: 8.23333333%;
  }
}

@media (min-width: 768px) {
  .col-sm-12 {
    width: 100%;
    *width: 99.9%;
  }
  .col-sm-11 {
    width: 91.66666667%;
    *width: 91.56666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
    *width: 83.23333333%;
  }
  .col-sm-9 {
    width: 75%;
    *width: 74.9%;
  }
  .col-sm-8 {
    width: 66.66666667%;
    *width: 66.56666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
    *width: 58.23333333%;
  }
  .col-sm-6 {
    width: 50%;
    *width: 49.9%;
  }
  .col-sm-5 {
    width: 41.66666667%;
    *width: 41.56666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
    *width: 33.23333333%;
  }
  .col-sm-3 {
    width: 25%;
    *width: 24.9%;
  }
  .col-sm-2 {
    width: 16.66666667%;
    *width: 16.56666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
    *width: 8.23333333%;
  }
}

@media (min-width: 992px) {
  .col-md-12 {
    width: 100%;
    *width: 99.9%;
  }
  .col-md-11 {
    width: 91.66666667%;
    *width: 91.56666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
    *width: 83.23333333%;
  }
  .col-md-9 {
    width: 75%;
    *width: 74.9%;
  }
  .col-md-8 {
    width: 66.66666667%;
    *width: 66.56666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
    *width: 58.23333333%;
  }
  .col-md-6 {
    width: 50%;
    *width: 49.9%;
  }
  .col-md-5 {
    width: 41.66666667%;
    *width: 41.56666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
    *width: 33.23333333%;
  }
  .col-md-3 {
    width: 25%;
    *width: 24.9%;
  }
  .col-md-2 {
    width: 16.66666667%;
    *width: 16.56666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
    *width: 8.23333333%;
  }
}

@media (min-width: 1200px) {
  .col-lg-12 {
    width: 100%;
    *width: 99.9%;
  }
  .col-lg-11 {
    width: 91.66666667%;
    *width: 91.56666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
    *width: 83.23333333%;
  }
  .col-lg-9 {
    width: 75%;
    *width: 74.9%;
  }
  .col-lg-8 {
    width: 66.66666667%;
    *width: 66.56666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
    *width: 58.23333333%;
  }
  .col-lg-6 {
    width: 50%;
    *width: 49.9%;
  }
  .col-lg-5 {
    width: 41.66666667%;
    *width: 41.56666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
    *width: 33.23333333%;
  }
  .col-lg-3 {
    width: 25%;
    *width: 24.9%;
  }
  .col-lg-2 {
    width: 16.66666667%;
    *width: 16.56666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
    *width: 8.23333333%;
  }
}

@media (min-width: 1600px) {
  .col-xlg-12 {
    width: 100%;
    *width: 99.9%;
  }
  .col-xlg-11 {
    width: 91.66666667%;
    *width: 91.56666667%;
  }
  .col-xlg-10 {
    width: 83.33333333%;
    *width: 83.23333333%;
  }
  .col-xlg-9 {
    width: 75%;
    *width: 74.9%;
  }
  .col-xlg-8 {
    width: 66.66666667%;
    *width: 66.56666667%;
  }
  .col-xlg-7 {
    width: 58.33333333%;
    *width: 58.23333333%;
  }
  .col-xlg-6 {
    width: 50%;
    *width: 49.9%;
  }
  .col-xlg-5 {
    width: 41.66666667%;
    *width: 41.56666667%;
  }
  .col-xlg-4 {
    width: 33.33333333%;
    *width: 33.23333333%;
  }
  .col-xlg-3 {
    width: 25%;
    *width: 24.9%;
  }
  .col-xlg-2 {
    width: 16.66666667%;
    *width: 16.56666667%;
  }
  .col-xlg-1 {
    width: 8.33333333%;
    *width: 8.23333333%;
  }
}


.align-start {
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.align-end {
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}

.align-end [class*="col-"] {
  vertical-align: bottom;
}

.align-center {
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.align-center [class*="col-"] {
  vertical-align: middle;
}

.align-baseline {
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
}

.align-content-start {
  -ms-flex-line-pack: start;
  -webkit-align-content: flex-start;
  align-content: flex-start;
}

.align-content-end {
  -ms-flex-line-pack: end;
  -webkit-align-content: flex-end;
  align-content: flex-end;
}

.align-content-end [class*="col-"] {
  vertical-align: bottom;
}

.align-content-center {
  -ms-flex-line-pack: center;
  -webkit-align-content: center;
  align-content: center;
}

.align-content-space-between {
  -ms-flex-line-pack: justify;
  -webkit-align-content: space-between;
  align-content: space-between;
}

.align-content-space-around {
  -ms-flex-line-pack: distribute;
  -webkit-align-content: space-around;
  align-content: space-around;
}

.align-self-stretch {
  -ms-flex-item-align: stretch;
  -webkit-align-self: stretch;
  align-self: stretch;
}

.align-self-start {
  -ms-flex-item-align: start;
  -webkit-align-self: flex-start;
  align-self: flex-start;
}

.align-self-end {
  -ms-flex-item-align: end;
  -webkit-align-self: flex-end;
  align-self: flex-end;
  vertical-align: bottom;
}

.align-self-center {
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center;
  vertical-align: middle;
}

.align-self-baseline {
  -ms-flex-item-align: baseline;
  -webkit-align-self: baseline;
  align-self: baseline;
  vertical-align: baseline;
}

.justify-start {
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.justify-end {
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.justify-center {
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.justify-space-between {
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

/* .justify-space-between.grid {
  text-align: justify;
  -moz-text-align-last: justify;
  text-align-last: justify;
} */

.justify-space-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

.grid-bleed [class*="col-"] {
  padding: 0;
}

.col-grid {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.col-grid.direction-row {
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.col-bleed {
  padding: 0;
}

.col-bleed-x {
  padding: $spacer 0;
}

.col-bleed-y {
  padding: 0 $spacer;
}

@media (max-width: 575px) {
  .hidden-xxs {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .hidden-xs {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .hidden-lg {
    display: none;
  }
}

@media (min-width: 1600px) {
  .hidden-xlg {
    display: none;
  }
}
