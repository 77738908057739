
      @import "./assets/vue/styles/_vars.scss";
      @import "./assets/vue/styles/_mixins.scss";
      $color-primary: #765691;
      $color-secondary: #ffce00;
			$color-border: #e6dde9;
			$color-bg: #f8f7f8;
			$color-text: #16051a;
    








































































































































































































































































.container {
  padding: 0 $spacer;
      
  .grid {
    min-height: 100vh;

    .bg {
      position: relative;
      overflow: hidden;
      min-height: 260px;
    }

    .inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      z-index: 9;
      padding: $spacer-lg;
    }

    .loginIcon {
      width: 200px;
      margin: auto 0;
    }

    .login-text {
      font-size: $fontsize-sm;
      color: #fff;
      opacity: 0.6;
      width: 100%;
      text-align: center;
      margin-bottom: $spacer-sm;
    }

    .bottomIcon {
      width: 100px;
    }

    .loginBgLeft {
      position: absolute;
      left: 0;
      top: 0;
      width: 33%;
      z-index: 1;
    }

    .loginBgRight {
      position: absolute;
      right: 0;
      top: 0;
      width: 33%;
      z-index: 1;
    }

    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      form {
        max-width: 300px;

        .toggle-visibility {
          position: absolute;
          bottom: 2px;
          height: 32px;
          width: 32px;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          svg {
            width: 16px;
            fill: $color-gray;
            transition: all .3s;
          }

          &:hover {
            svg {
              fill: darken($color-gray, 20%);
            }
          }
        }

        .btn {
          width: 100%;
        }
      }
    }
  }
}
