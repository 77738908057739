
      @import "./assets/vue/styles/_vars.scss";
      @import "./assets/vue/styles/_mixins.scss";
      $color-primary: #765691;
      $color-secondary: #ffce00;
			$color-border: #e6dde9;
			$color-bg: #f8f7f8;
			$color-text: #16051a;
    
@import 'reset';
@import 'typo';
@import 'helper';
@import 'grid';
@import 'forms';
@import 'vselect';

@font-face {
  font-family: 'Gotham';
  src:  url('../fonts/SVN-Gotham-Book.woff2') format('woff2'),
        url('../fonts/SVN-Gotham-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src:  url('../fonts/SVN-Gotham-Regular.woff2') format('woff2'),
        url('../fonts/SVN-Gotham-Regular.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

*, *::after, *::before {
  box-sizing: border-box;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  height: 100%;
  overflow: hidden;
  overscroll-behavior: none;
}

html {
  background: $color-bg !important;
  color: $color-text;
}

img, svg {
  width: 100%;
  height: auto;
}

.app {
  height: 100%;
  display: flex;
  overflow: hidden;
  
  > .content {
    overflow-y: scroll;
		overflow-x: hidden;
    scroll-behavior: smooth;
    overscroll-behavior: none;
    flex-grow: 1;

		> * {
			min-height: 100%;
			width: 100%;
		}
  }
}

a {
  color: $color-primary;
	font-weight: $medium;
  text-decoration: none;
  transition: color .3s;
  will-change: color;

  @media (hover: hover) {
    &:hover {
      color: $color-secondary;
    }
  }

  &.link {
    color: darken($color-primary, 35%);
    font-weight: $medium;
    border-bottom: 2px solid $color-primary;
    padding: 3px 0 $spacer-xs 0;

    @media (hover: hover) {
      &:hover {
        color: $color-primary;
      }
    }
  }
}

button {
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  transition: all .3s;
  will-change: auto;
  font-size: inherit;

  &:focus {
    outline: 0;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.submitting {
    background: $color-primary;
  }

  &.disabled {
    opacity: 0.5;

    @media (hover: hover) {
      &:hover {
        background: $color-primary;
      }
    }
  }
}

.btn {
  display: block;
  padding: 6px $spacer $spacer-sm $spacer;
  background: $color-primary;
  color: $color-bg;
  border: none;
  cursor: pointer;
  font-weight: $medium;
  border-radius: 4px;
  font-family: inherit;
  transition: all .3s;
  line-height: 14px;
  min-height: 0;
  text-align: center;
  border: 2px solid $color-primary;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  user-select: none;

  > .plus {
    font-size: 20px;
    line-height: 0;
    display: inline-block;
    vertical-align: sub;
  }

  &.red {
    background: $color-error;
    border-color: $color-error;

    @media (hover: hover) {
      &:hover {
        background: darken($color-error, 10%);
        border-color: darken($color-error, 10%);
      }
    }
  }

  @media (hover: hover) {
    &:hover {
      color: $color-bg;
      background: darken($color-primary, 15%);
      border-color: darken($color-primary, 15%);
    }
  }

  &.btn-secondary {
    background: $color-bg;
    color: $color-primary;
    border: 2px solid $color-primary;

    @media (hover: hover) {
      &:hover {
        color: darken($color-primary, 35%);
        border-color: darken($color-primary, 35%);
      }
    }
  }

  &.small {
    font-size: $fontsize-sm;
    padding: 3px 4px 4px 4px;
  }

	&.block {
		width: 100%;
	}
}

.btn-set {
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: $spacer-sm;
  }
}

// FADE TRANSITION
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}