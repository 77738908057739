
      @import "./assets/vue/styles/_vars.scss";
      @import "./assets/vue/styles/_mixins.scss";
      $color-primary: #765691;
      $color-secondary: #ffce00;
			$color-border: #e6dde9;
			$color-bg: #f8f7f8;
			$color-text: #16051a;
    
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.general .logo {
	@include sm() {
		order: 1;
	}
}

.compiling {
  display: flex;
	flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.75);
  z-index: 9999;

  * {
    color: $color-bg;
  }
}

.delete-logo {
	margin-top: $spacer-sm;
	display: block;
	text-align: center;
	font-weight: $medium;
	font-size: $fontsize-sm;
	color: $color-error;
}

.fieldset {
  input[type="text"] {
    padding-right: 50px;
  }

  input[type="color"] {
    position: absolute;
    right: $spacer;
    bottom: 4px;
    padding: 0;
    border: 0;
    background: $color-bg;
    width: 40px;
  }

  &.surcharge {
    > span {
      position: absolute;
      line-height: 1;
      right: 0;
      bottom: 10px;
      color: $color-gray;
    }
  }
}

.upload {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: darken($color-primary, 50%);
    transition: all .3s;
    position: relative;
		border-radius: 4px;

    &.cert,
    &.key {
      min-height: 0;
      padding: $spacer-sm;
      text-align: left;
      align-items: flex-start;
      border: 0;
      background: $color-error-light;
      margin-top: $spacer-sm;

      &.exists {
        background: $color-success-light;
      }

      > span {
        margin-top: 0;

        &.inner {
          display: flex;
          width: 100%;

          span.change {
            margin-left: auto;
            margin-right: $spacer-sm;
          }

          .delete {
            z-index: 1;
            color: $color-error;
          }
        }
      }
    }

    > span {
      margin-top: $spacer;
      line-height: 1.4;
    }

    .no-logo {
      background: $color-border;
			color: $color-gray;
      width: 100%;
			height: 100px;
			text-align: center;
			display: flex;
			text-align: center;
			justify-content: center;
			align-items: center;
    }

    input[type="file"] {
      opacity: 0;
      overflow: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    input:focus + label {
      outline: 1px dotted #000;
      outline: -webkit-focus-ring-color auto 5px;
    }

    &:hover {
      border-color: $color-primary;
    }

    &.dragenter {
      border-color: $color-primary;
    }

    &.uploading {
      border-color: $color-success;
      pointer-events: none;
    }
  }
