
      @import "./assets/vue/styles/_vars.scss";
      @import "./assets/vue/styles/_mixins.scss";
      $color-primary: #765691;
      $color-secondary: #ffce00;
			$color-border: #e6dde9;
			$color-bg: #f8f7f8;
			$color-text: #16051a;
    
































































































































































































































































































































































































































































































































































































































































































































































.page-head .actions {
  display: flex;

  .btn:last-child {
    margin-left: $spacer-sm;
  }
}

.mailboxes-table {
  .row {
    grid-template-columns: 3fr 1fr;
  }
}

.fieldsetgroup.address {
  .fieldset {
    margin-right: 10px;
    width: auto;

    &.account {
      max-width: 130px;
    }

    &.at {
      color: $color-gray;
      max-width: 12px;
      font-weight: 500;
      max-width: 12px;
      padding-top: 24px;
    }

    &.domain {
      flex-shrink: 0;
      flex-grow: 1;
      margin-right: 0;
    }
  }
}
