@mixin xs() {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin sm() {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin md() {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin lg() {
  @media (min-width: 1200px) {
    @content;
  }
}