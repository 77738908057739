.v-select {
  position: relative;
  font-family: inherit;
}

@-webkit-keyframes vSelectSpinner {
  0% {transform:rotate(0deg)}
  to {transform:rotate(1turn)}
}

@keyframes vSelectSpinner {
  0% {transform:rotate(0deg)}
  to {transform:rotate(1turn)}
}

.vs__fade-enter-active,
.vs__fade-leave-active {
  pointer-events: none;
  transition: opacity .15s;
}

.vs__fade-enter,
.vs__fade-leave-to {
  opacity: 0;
  // transform: translateY(-3px);
}

.vs--disabled .vs__clear,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__open-indicator,
.vs--disabled .vs__search,
.vs--disabled .vs__selected {
  cursor: not-allowed;
  background-color: transparent;
	border-color: lighten($color-primary, 50%);
	fill: lighten($color-primary, 50%);
}

.vs__dropdown-toggle {
  appearance: none;
  display: flex;
  // padding: 0 0 $spacer-xs;
  padding: 0;
  background: $color-bg;
  border-bottom: 2px solid lighten($color-primary, 25%);
  white-space: normal;
  min-height: 34px;
}

.vs__selected-options {
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
  flex-wrap: wrap;
  position: relative;
  // justify-content: center;
  min-width: 0;
  // padding: 0 $spacer-xs;
  // padding-bottom: $spacer-xs;
}

.vs--single .vs__selected-options {
  flex-wrap: nowrap;
}

.vs__actions {
  display: flex;
  align-items: center;
  padding: $spacer-xs 0 0 $spacer-xs;
  cursor: pointer;
}

.vs--searchable .vs__dropdown-toggle {
  cursor: text;
}

.vs--unsearchable .vs__dropdown-toggle {
  cursor: pointer;
}

.vs--open {
  &:not(.drop-up) .vs__dropdown-toggle {
      // border-bottom-color: transparent;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
  }

  &.drop-up .vs__dropdown-toggle {
      // border-top-color: transparent;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
  }
}

.vs__open-indicator {
  width: 14px;
  fill: lighten($color-primary, 25%);
  transform: scale(1);
  transition: transform .15s cubic-bezier(1,-.115,.975,.855);
  transition-timing-function: cubic-bezier(1,-.115,.975,.855);
}

.vs--open .vs__open-indicator {
  transform:rotate(180deg) scale(1);
}

.vs--loading .vs__open-indicator {
  opacity: 0;
}

.vs__clear {
  fill: $color-error;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  margin-right: $spacer-sm;
  min-width: auto;
  width: 10px;
}

.fieldset-error .vs__dropdown-toggle,
.fieldset-error .vs__dropdown-menu {
  border-color: $color-error !important;
}

.vs__dropdown-menu {
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: calc(100% - 2px);
  left: 0;
  z-index: 1000;
  padding: 0;
  margin: 0;
  width: 100%;
  max-height: 350px;
  min-width: 80px;
  overflow-y: auto;
  box-shadow: 0 3px 3px 0 rgba(0,0,0,.15);
  text-align: left;
  list-style: none;
  background: $color-primary;
}

[data-popper-placement='top'] {
  // border-radius: 5px 5px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  border-top: 2px solid lighten($color-primary, 25%);
  box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.15)
}

.vs__no-options {
  padding: $spacer;
  color: $color-bg;

  a {
    font-weight: $medium;
    color: $color-secondary;
  }
}

.vs__dropdown-option {
  line-height: 1.6;
  padding: $spacer-sm;
  clear: both;
  color: $color-bg;
  white-space: nowrap;
  transition: all .3s;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;

  em {
      margin-left: auto;
      font-style: inherit;
      color: lighten($color-primary, 25%);
      font-size: 9px;
      font-weight: $medium;
      line-height: 20px;
      // text-transform: uppercase;

      &.circle {
          width: 10px;
          height: 10px;
          border-radius: 100%;
          background: $color-error;
      }

      &.red {
          color: $color-error;
      }

      &.green {
          color: $color-success;
      }

      &.yellow {
          color: $color-warning;
      }
  }
}

.vs__dropdown-option:hover {
  cursor: pointer
}

.vs__dropdown-option--highlight {
  background: darken($color-primary, 15%);
  color: $color-bg;
}

// .vs__dropdown-option--selected {
  // color: $color-darkerpurple !important;
  // font-weight: 500 !important;
// }

.vs__dropdown-option--disabled {
  background: inherit;
  color: lighten($color-primary, 50%) !important;
  cursor: not-allowed !important;
}

.vs__dropdown-option--disabled:hover {
  cursor:inherit
}

.vs__selected {
  display: flex;
  align-items: center;
  z-index: 0;
  line-height: 1.6;
  color: darken($color-primary, 35%);
}

.vs__deselect {
  display: inline-flex;
  appearance: none;
  margin-left: $spacer-xs;
  padding: 0;
  border: 0;
  cursor: pointer;
  background: none;
  fill: $color-error;
  text-shadow: 0 1px 0 #fff;
}

.vs--single .vs__selected {
  background-color: transparent;
  border-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  padding: $spacer-xs $spacer-xs 0 0;
  // line-height: 26px;
}

.vs--single.vs--open .vs__selected {
  position: absolute;
  opacity: .4;
  width: 100%;
  padding-right: $spacer;
  // top: 0;
  // left: 0;
  // right: 0;
}

.vs--single.vs--searching .vs__selected {
  display: none;
}

.vs__search::-webkit-search-cancel-button,
.vs__search::-ms-clear,
.vs__search::-webkit-search-decoration,
.vs__search::-webkit-search-results-button,
.vs__search::-webkit-search-results-decoration {
  display: none;
}

.vs__search,
.vs__search:focus {
  appearance: none;
  border: none;
  outline: none;
  // margin: $spacer-xs 0 0;
  padding: 0;
  background: none;
  box-shadow: none;
  width: 0;
  max-width: 100%;
  flex-grow: 1;
  z-index: 1;
  font-size: $fontsize;
}

.vs--unsearchable .vs__search {
  opacity:1
}

.vs--unsearchable:not(.vs--disabled) .vs__search:hover {
  cursor: pointer
}

.vs--single.vs--searching:not(.vs--open):not(.vs--loading) .vs__search {
  opacity: .2
}

.vs__spinner {
  align-self: center;
  opacity: 0;
  font-size: 5px;
  text-indent: -9999em;
  overflow: hidden;
  border: .9em solid hsla(0,0%,39.2%,.1);
  border-left-color: rgba(60,60,60,.45);
  transform: translateZ(0);
  animation: vSelectSpinner 1.1s linear infinite;
  transition: opacity .1s
}

.vs__spinner,
.vs__spinner:after {
  border-radius: 50%;
  width: 5em;
  height: 5em
}

.vs--loading .vs__spinner {
  opacity: 1
}

.v-select:not(.vs--single) {
  .vs__selected {
      background: lighten($color-primary, 50%);
      margin: 0 $spacer-xs $spacer-xs 0;
      border-radius: 4px;
      padding: $spacer-xs;
      font-size: $fontsize-sm;
      line-height: 1;
      align-self: flex-end;
  }
}