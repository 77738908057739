// replaced by webpack sass-loader
$color-primary: #765691;
$color-secondary: #ffce00;
$color-bg: #f8f7f8;
$color-text: #16051a;
$color-border: #e6dde9;
$color-gray: #a7a7a7;

// State Colors
$color-info: #3074b9;
$color-success: #60a74b;
$color-success-light: lighten($color-success, 40%);
$color-warning: #e77e1b;
$color-yellow: #f7e11b;
$color-error: #c53b3b;
$color-error-light: lighten($color-error, 40%);

// Fontsizes
$fontsize-sm: 10px;
$fontsize: 12px;
$fontsize-lg: 18px;
$fontsize-xl: 24px;
$fontsize-xxl: 32px;

// Font Weights
$regular: 400;
$medium: 500;

// Spacing
$spacer-xs: 5px;
$spacer-sm: 10px;
$spacer: 20px;
$spacer-lg: 40px;
$spacer-xl: 80px;
$spacer-xxl: 160px;