
      @import "./assets/vue/styles/_vars.scss";
      @import "./assets/vue/styles/_mixins.scss";
      $color-primary: #765691;
      $color-secondary: #ffce00;
			$color-border: #e6dde9;
			$color-bg: #f8f7f8;
			$color-text: #16051a;
    

























































































































































































textarea { 
    height: auto;
    resize: none;
    }
.support-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.support-container-title{
    margin-top: 5%;
}

.support-ticket-creator{
    margin-bottom:4%;

    width: 80%;
    box-shadow: 2.8px 2.8px 2.2px rgb(0 0 0 / 2%), 6.7px 6.7px 5.3px rgb(0 0 0 / 3%), 12.5px 12.5px 10px rgb(0 0 0 / 4%), 22.3px 22.3px 17.9px rgb(0 0 0 / 4%), 41.8px 41.8px 33.4px rgb(0 0 0 / 5%), 100px 100px 80px rgb(0 0 0 / 7%);
    border-bottom-right-radius: 3rem;
    border-bottom-left-radius: 3rem;
    margin-top: 4%;
}

.support-ticket-container{
    
    width: 80%;
}

.support-ticket-container{
    width: 80%;
}

.support-ticket-title{
    margin-top: 3%;
    margin-left: 3%;
    border-bottom: 2px solid #b5a0c7;
    margin-right: 3%;

}

.support-ticket-item{
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    box-shadow: 2.8px 2.8px 2.2px rgb(0 0 0 / 2%), 6.7px 6.7px 5.3px rgb(0 0 0 / 3%), 12.5px 12.5px 10px rgb(0 0 0 / 4%), 22.3px 22.3px 17.9px rgb(0 0 0 / 4%), 41.8px 41.8px 33.4px rgb(0 0 0 / 5%), 100px 100px 80px rgb(0 0 0 / 7%);
    width: 60%;
    min-height: 5rem;
    border-bottom-right-radius: 2rem;
      border: 2px solid #e6dde9;
    transition: border-color 0.3s;
}

.support-ticket-item-title{
    font-size: 1.5em;
    font-weight: 500;
    margin-top: 1rem;
    margin-left: 1rem;
}

.support-ticket-item-body{

}

.support-ticket-item-link{
    border: 2px solid #765691;
    width: 30%;
    border-bottom-right-radius: 2rem;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 66%;
}


.support-ticket-creator-button{
    margin-bottom: 4rem;
    width: 80%;
    margin-left: 10%;}
.support-ticket-creator-title{
    margin-bottom: 2rem;

}
.support-ticket-creator-body{
    margin-bottom: 2rem;
    height: 10rem;

}
.support-ticket-creator-select{
    margin-bottom: 2rem;
}
