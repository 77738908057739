html, body {
  font-family: "Gotham", "Arial", sans-serif;
  font-size: $fontsize;
  font-weight: $regular;
  line-height: 1.6;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
	color: darken($color-primary, 50%);
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  margin: 0;
  font-weight: $medium;
  line-height: inherit;
  color: darken($color-primary, 35%);
}

h1, .h1 {
  font-size: $fontsize-xxl;
  line-height: 1.4;

  // @include lg() {
  //   font-size: $fontsize-xxl;
  // }
}

h2, .h2 {
  font-size: $fontsize-xl;

  // @include lg() {
  //   font-size: $fontsize-xl;
  // }
}

h3, .h3 {
  font-size: $fontsize-lg;

  // @include sm() {
  //   font-size: $fontsize-lg;
  // }
}

h4, .h4 {
  font-size: inherit;
}

p {
  font-size: inherit;
  margin-bottom: 2rem;
  line-height: inherit;

  &:last-of-type {
    margin-bottom: 0;
  }
}

b, strong {
  font-weight: $medium;
}