
      @import "./assets/vue/styles/_vars.scss";
      @import "./assets/vue/styles/_mixins.scss";
      $color-primary: #765691;
      $color-secondary: #ffce00;
			$color-border: #e6dde9;
			$color-bg: #f8f7f8;
			$color-text: #16051a;
    










































































































































































































































































































































































































































































































.databases-table {
    .row

{
    grid-template-columns: 1fr 1fr ;
}

}

.prefix {
    position: absolute;
    left: 0;
    bottom: 8px;
    color: $color-gray;
}

input.has-prefix {
    padding-left: 65px;
}
