
// Margins
.mx-xs {
  margin-left: $spacer-xs;
  margin-right: $spacer-xs;
}

.mx-sm {
  margin-left: $spacer-sm;
  margin-right: $spacer-sm;
}

.mx {
  margin-left: $spacer;
  margin-right: $spacer;
}

.mx-lg {
  margin-left: $spacer-lg;
  margin-right: $spacer-lg;
}

.mx-xl {
  margin-left: $spacer-xl;
  margin-right: $spacer-xl;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-none {
  margin-left: 0;
  margin-right: 0;
}


.my-xs {
  margin-top: $spacer-xs;
  margin-bottom: $spacer-xs;
}

.my-sm {
  margin-top: $spacer-sm;
  margin-bottom: $spacer-sm;
}

.my {
  margin-top: $spacer;
  margin-bottom: $spacer;
}

.my-lg {
  margin-top: $spacer-lg;
  margin-bottom: $spacer-lg;
}

.my-xl {
  margin-top: $spacer-xl;
  margin-bottom: $spacer-xl;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.my-none {
  margin-top: 0;
  margin-bottom: 0;
}


.mt-xs {
  margin-top: $spacer-xs;
}

.mt-sm {
  margin-top: $spacer-sm;
}

.mt {
  margin-top: $spacer;
}

.mt-lg {
  margin-top: $spacer-lg;
}

.mt-xl {
  margin-top: $spacer-xl;
}

.mt-xxl {
  margin-top: $spacer-xxl;
}

.mt-auto {
  margin-top: auto;
}

.mt-none {
  margin-top: 0;
}


.mb-xs {
  margin-bottom: $spacer-xs;
}

.mb-sm {
  margin-bottom: $spacer-sm;
}

.mb {
  margin-bottom: $spacer;
}

.mb-lg {
  margin-bottom: $spacer-lg;
}

.mb-xl {
  margin-bottom: $spacer-xl;
}

.mb-xxl {
  margin-bottom: $spacer-xxl;
}

.mb-auto {
  margin-bottom: auto;
}

.mb-none {
  margin-bottom: 0;
}


.mr-xs {
  margin-right: $spacer-xs;
}

.mr-sm {
  margin-right: $spacer-sm;
}

.mr {
  margin-right: $spacer;
}

.mr-lg {
  margin-right: $spacer-lg;
}

.mr-xl {
  margin-right: $spacer-xl;
}

.mr-auto {
  margin-right: auto;
}

.mr-none {
  margin-right: 0;
}


.ml-xs {
  margin-left: $spacer-xs;
}

.ml-sm {
  margin-left: $spacer-sm;
}

.ml {
  margin-left: $spacer;
}

.ml-lg {
  margin-left: $spacer-lg;
}

.ml-xl {
  margin-left: $spacer-xl;
}

.ml-auto {
  margin-left: auto;
}

.ml-none {
  margin-left: 0;
}

.mb-fluid {
  margin-bottom: 5vw;
}

.mt-fluid {
  margin-top: 5vw;
}

.ml-fluid {
  margin-left: 5vw;
}

.mr-fluid {
  margin-right: 5vw;
}

@include xs() {
  .mx-xs-xs {
    margin-left: $spacer-xs;
    margin-right: $spacer-xs;
  }
  
  .mx-xs-sm {
    margin-left: $spacer-sm;
    margin-right: $spacer-sm;
  }
  
  .mx_xs {
    margin-left: $spacer;
    margin-right: $spacer;
  }
  
  .mx-xs-lg {
    margin-left: $spacer-lg;
    margin-right: $spacer-lg;
  }
  
  .mx-xs-xl {
    margin-left: $spacer-xl;
    margin-right: $spacer-xl;
  }
  
  .mx-xs-auto {
    margin-left: auto;
    margin-right: auto;
  }
  
  .mx-xs-none {
    margin-left: 0;
    margin-right: 0;
  }
  
  
  .my-xs-xs {
    margin-top: $spacer-xs;
    margin-bottom: $spacer-xs;
  }
  
  .my-xs-sm {
    margin-top: $spacer-sm;
    margin-bottom: $spacer-sm;
  }
  
  .my_xs {
    margin-top: $spacer;
    margin-bottom: $spacer;
  }
  
  .my-xs-lg {
    margin-top: $spacer-lg;
    margin-bottom: $spacer-lg;
  }
  
  .my-xs-xl {
    margin-top: $spacer-xl;
    margin-bottom: $spacer-xl;
  }
  
  .my-xs-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .my-xs-none {
    margin-top: 0;
    margin-bottom: 0;
  }
  
  
  .mt-xs-xs {
    margin-top: $spacer-xs;
  }
  
  .mt-xs-sm {
    margin-top: $spacer-sm;
  }
  
  .mt_xs {
    margin-top: $spacer;
  }
  
  .mt-xs-lg {
    margin-top: $spacer-lg;
  }
  
  .mt-xs-xl {
    margin-top: $spacer-xl;
  }
  
  .mt-xs-auto {
    margin-top: auto;
  }

  .mt-xs-none {
    margin-top: 0;
  }
  
  
  .mb-xs-xs {
    margin-bottom: $spacer-xs;
  }
  
  .mb-xs-sm {
    margin-bottom: $spacer-sm;
  }
  
  .mb_xs {
    margin-bottom: $spacer;
  }
  
  .mb-xs-lg {
    margin-bottom: $spacer-lg;
  }
  
  .mb-xs-xl {
    margin-bottom: $spacer-xl;
  }
  
  .mb-xs-auto {
    margin-bottom: auto;
  }

  .mb-xs-none {
    margin-bottom: 0;
  }
  
  
  .mr-xs-xs {
    margin-right: $spacer-xs;
  }
  
  .mr-xs-sm {
    margin-right: $spacer-sm;
  }
  
  .mr_xs {
    margin-right: $spacer;
  }
  
  .mr-xs-lg {
    margin-right: $spacer-lg;
  }
  
  .mr-xs-xl {
    margin-right: $spacer-xl;
  }
  
  .mr-xs-auto {
    margin-right: auto;
  }

  .mr-xs-none {
    margin-right: 0;
  }
  
  
  .ml-xs-xs {
    margin-left: $spacer-xs;
  }
  
  .ml-xs-sm {
    margin-left: $spacer-sm;
  }
  
  .ml_xs {
    margin-left: $spacer;
  }
  
  .ml-xs-lg {
    margin-left: $spacer-lg;
  }
  
  .ml-xs-xl {
    margin-left: $spacer-xl;
  }
  
  .ml-xs-auto {
    margin-left: auto;
  }

  .ml-xs-none {
    margin-left: 0;
  }
}

@include sm() {
  .mx-sm-xs {
    margin-left: $spacer-xs;
    margin-right: $spacer-xs;
  }
  
  .mx-sm-sm {
    margin-left: $spacer-sm;
    margin-right: $spacer-sm;
  }
  
  .mx_sm {
    margin-left: $spacer;
    margin-right: $spacer;
  }
  
  .mx-sm-lg {
    margin-left: $spacer-lg;
    margin-right: $spacer-lg;
  }
  
  .mx-sm-xl {
    margin-left: $spacer-xl;
    margin-right: $spacer-xl;
  }
  
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto;
  }
  
  .mx-sm-none {
    margin-left: 0;
    margin-right: 0;
  }
  
  
  .my-sm-xs {
    margin-top: $spacer-xs;
    margin-bottom: $spacer-xs;
  }
  
  .my-sm-sm {
    margin-top: $spacer-sm;
    margin-bottom: $spacer-sm;
  }
  
  .my_sm {
    margin-top: $spacer;
    margin-bottom: $spacer;
  }
  
  .my-sm-lg {
    margin-top: $spacer-lg;
    margin-bottom: $spacer-lg;
  }
  
  .my-sm-xl {
    margin-top: $spacer-xl;
    margin-bottom: $spacer-xl;
  }
  
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .my-sm-none {
    margin-top: 0;
    margin-bottom: 0;
  }
  
  
  .mt-sm-xs {
    margin-top: $spacer-xs;
  }
  
  .mt-sm-sm {
    margin-top: $spacer-sm;
  }
  
  .mt_sm {
    margin-top: $spacer;
  }
  
  .mt-sm-lg {
    margin-top: $spacer-lg;
  }
  
  .mt-sm-xl {
    margin-top: $spacer-xl;
  }
  
  .mt-sm-auto {
    margin-top: auto;
  }

  .mt-sm-none {
    margin-top: 0;
  }
  
  
  .mb-sm-xs {
    margin-bottom: $spacer-xs;
  }
  
  .mb-sm-sm {
    margin-bottom: $spacer-sm;
  }
  
  .mb_sm {
    margin-bottom: $spacer;
  }
  
  .mb-sm-lg {
    margin-bottom: $spacer-lg;
  }
  
  .mb-sm-xl {
    margin-bottom: $spacer-xl;
  }
  
  .mb-sm-auto {
    margin-bottom: auto;
  }

  .mb-sm-none {
    margin-bottom: 0;
  }
  
  
  .mr-sm-xs {
    margin-right: $spacer-xs;
  }
  
  .mr-sm-sm {
    margin-right: $spacer-sm;
  }
  
  .mr_sm {
    margin-right: $spacer;
  }
  
  .mr-sm-lg {
    margin-right: $spacer-lg;
  }
  
  .mr-sm-xl {
    margin-right: $spacer-xl;
  }
  
  .mr-sm-auto {
    margin-right: auto;
  }

  .mr-sm-none {
    margin-right: 0;
  }
  
  
  .ml-sm-xs {
    margin-left: $spacer-xs;
  }
  
  .ml-sm-sm {
    margin-left: $spacer-sm;
  }
  
  .ml_sm {
    margin-left: $spacer;
  }
  
  .ml-sm-lg {
    margin-left: $spacer-lg;
  }
  
  .ml-sm-xl {
    margin-left: $spacer-xl;
  }
  
  .ml-sm-auto {
    margin-left: auto;
  }

  .ml-sm-none {
    margin-left: 0;
  }
}

@include md() {
  .mx-md-xs {
    margin-left: $spacer-xs;
    margin-right: $spacer-xs;
  }
  
  .mx-md-sm {
    margin-left: $spacer-sm;
    margin-right: $spacer-sm;
  }
  
  .mx_md {
    margin-left: $spacer;
    margin-right: $spacer;
  }
  
  .mx-md-lg {
    margin-left: $spacer-lg;
    margin-right: $spacer-lg;
  }
  
  .mx-md-xl {
    margin-left: $spacer-xl;
    margin-right: $spacer-xl;
  }
  
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto;
  }
  
  .mx-md-none {
    margin-left: 0;
    margin-right: 0;
  }
  
  
  .my-md-xs {
    margin-top: $spacer-xs;
    margin-bottom: $spacer-xs;
  }
  
  .my-md-sm {
    margin-top: $spacer-sm;
    margin-bottom: $spacer-sm;
  }
  
  .my_md {
    margin-top: $spacer;
    margin-bottom: $spacer;
  }
  
  .my-md-lg {
    margin-top: $spacer-lg;
    margin-bottom: $spacer-lg;
  }
  
  .my-md-xl {
    margin-top: $spacer-xl;
    margin-bottom: $spacer-xl;
  }
  
  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .my-md-none {
    margin-top: 0;
    margin-bottom: 0;
  }
  
  
  .mt-md-xs {
    margin-top: $spacer-xs;
  }
  
  .mt-md-sm {
    margin-top: $spacer-sm;
  }
  
  .mt_md {
    margin-top: $spacer;
  }
  
  .mt-md-lg {
    margin-top: $spacer-lg;
  }
  
  .mt-md-xl {
    margin-top: $spacer-xl;
  }
  
  .mt-md-auto {
    margin-top: auto;
  }

  .mt-md-none {
    margin-top: 0;
  }
  
  
  .mb-md-xs {
    margin-bottom: $spacer-xs;
  }
  
  .mb-md-sm {
    margin-bottom: $spacer-sm;
  }
  
  .mb_md {
    margin-bottom: $spacer;
  }
  
  .mb-md-lg {
    margin-bottom: $spacer-lg;
  }
  
  .mb-md-xl {
    margin-bottom: $spacer-xl;
  }
  
  .mb-md-auto {
    margin-bottom: auto;
  }

  .mb-md-none {
    margin-bottom: 0;
  }
  
  
  .mr-md-xs {
    margin-right: $spacer-xs;
  }
  
  .mr-md-sm {
    margin-right: $spacer-sm;
  }
  
  .mr_md {
    margin-right: $spacer;
  }
  
  .mr-md-lg {
    margin-right: $spacer-lg;
  }
  
  .mr-md-xl {
    margin-right: $spacer-xl;
  }
  
  .mr-md-auto {
    margin-right: auto;
  }

  .mr-md-none {
    margin-right: 0;
  }
  
  
  .ml-md-xs {
    margin-left: $spacer-xs;
  }
  
  .ml-md-sm {
    margin-left: $spacer-sm;
  }
  
  .ml_md {
    margin-left: $spacer;
  }
  
  .ml-md-lg {
    margin-left: $spacer-lg;
  }
  
  .ml-md-xl {
    margin-left: $spacer-xl;
  }
  
  .ml-md-auto {
    margin-left: auto;
  }

  .ml-md-none {
    margin-left: 0;
  }
}

@include lg() {
  .mx-lg-xs {
    margin-left: $spacer-xs;
    margin-right: $spacer-xs;
  }
  
  .mx-lg-sm {
    margin-left: $spacer-sm;
    margin-right: $spacer-sm;
  }
  
  .mx_lg {
    margin-left: $spacer;
    margin-right: $spacer;
  }
  
  .mx-lg-lg {
    margin-left: $spacer-lg;
    margin-right: $spacer-lg;
  }
  
  .mx-lg-xl {
    margin-left: $spacer-xl;
    margin-right: $spacer-xl;
  }
  
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto;
  }
  
  .mx-lg-none {
    margin-left: 0;
    margin-right: 0;
  }
  
  
  .my-lg-xs {
    margin-top: $spacer-xs;
    margin-bottom: $spacer-xs;
  }
  
  .my-lg-sm {
    margin-top: $spacer-sm;
    margin-bottom: $spacer-sm;
  }
  
  .my_lg {
    margin-top: $spacer;
    margin-bottom: $spacer;
  }
  
  .my-lg-lg {
    margin-top: $spacer-lg;
    margin-bottom: $spacer-lg;
  }
  
  .my-lg-xl {
    margin-top: $spacer-xl;
    margin-bottom: $spacer-xl;
  }
  
  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .my-lg-none {
    margin-top: 0;
    margin-bottom: 0;
  }
  
  
  .mt-lg-xs {
    margin-top: $spacer-xs;
  }
  
  .mt-lg-sm {
    margin-top: $spacer-sm;
  }
  
  .mt_lg {
    margin-top: $spacer;
  }
  
  .mt-lg-lg {
    margin-top: $spacer-lg;
  }
  
  .mt-lg-xl {
    margin-top: $spacer-xl;
  }
  
  .mt-lg-auto {
    margin-top: auto;
  }

  .mt-lg-none {
    margin-top: 0;
  }
  
  
  .mb-lg-xs {
    margin-bottom: $spacer-xs;
  }
  
  .mb-lg-sm {
    margin-bottom: $spacer-sm;
  }
  
  .mb_lg {
    margin-bottom: $spacer;
  }
  
  .mb-lg-lg {
    margin-bottom: $spacer-lg;
  }
  
  .mb-lg-xl {
    margin-bottom: $spacer-xl;
  }
  
  .mb-lg-auto {
    margin-bottom: auto;
  }

  .mb-lg-none {
    margin-bottom: 0;
  }
  
  
  .mr-lg-xs {
    margin-right: $spacer-xs;
  }
  
  .mr-lg-sm {
    margin-right: $spacer-sm;
  }
  
  .mr_lg {
    margin-right: $spacer;
  }
  
  .mr-lg-lg {
    margin-right: $spacer-lg;
  }
  
  .mr-lg-xl {
    margin-right: $spacer-xl;
  }
  
  .mr-lg-auto {
    margin-right: auto;
  }

  .mr-lg-none {
    margin-right: 0;
  }
  
  
  .ml-lg-xs {
    margin-left: $spacer-xs;
  }
  
  .ml-lg-sm {
    margin-left: $spacer-sm;
  }
  
  .ml_lg {
    margin-left: $spacer;
  }
  
  .ml-lg-lg {
    margin-left: $spacer-lg;
  }
  
  .ml-lg-xl {
    margin-left: $spacer-xl;
  }
  
  .ml-lg-auto {
    margin-left: auto;
  }

  .ml-lg-none {
    margin-left: 0;
  }
}


// Text Align
.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}


// Font Weight
.regular {
  font-weight: $regular;
}

.medium {
  font-weight: $medium;
}

// Graphs
.data-info {
  display: flex;
  justify-content: space-between;
  font-size: $fontsize-sm;
  flex-wrap: wrap;
}

.vtc {
  width: 100%;
  height: 60px;
  background: lighten($color-info, 50%);

  .stroke {
    stroke-width: 2;
    stroke: $color-info;
  }
  .fill {
    fill: lighten($color-info, 40%);
  }
  .active-line {
    stroke: $color-bg;
  }
  .point {
    display: none;
    fill: $color-info;
    stroke: $color-info;
  }
  .point.is-active {
    display: block;
  }

  &.clicked {
    height: 120px;
  }

  &.yellow {
    background: lighten($color-yellow, 50%);

    .stroke {
      stroke: $color-yellow;
    }
    .fill {
      fill: lighten($color-yellow, 40%);
    }
    .point {
      fill: $color-yellow;
      stroke: $color-yellow;
    }
  }

  &.orange {
    background: lighten($color-warning, 50%);

    .stroke {
      stroke: $color-warning;
    }
    .fill {
      fill: lighten($color-warning, 40%);
    }
    .point {
      fill: $color-warning;
      stroke: $color-warning;
    }
  }

  &.red {
    background: lighten($color-error, 50%);

    .stroke {
      stroke: $color-error;
    }
    .fill {
      fill: lighten($color-error, 40%);
    }
    .point {
      fill: $color-error;
      stroke: $color-error;
    }
  }
}

// Page Head
.page-head {
  display: flex;
  align-items: center;

  .title {
    margin-right: auto;

    > span {
      font-size: $fontsize;
      color: lighten($color-primary, 25%);
      vertical-align: super;
    }
  }

  .actions {
    margin-left: $spacer;
  }
}

// SUBHEAD
.subhead {
	background: linear-gradient(180deg, #f1f2f3, transparent);
}

legend {
  font-size: $fontsize-sm;
  color: $color-gray;
  margin-top: $spacer-sm;
}

.info {
	padding: $spacer;
  border: 2px solid $color-border;
  background: #f1f1f1;
  border-radius: 4px;
	// font-weight: $medium;

	&.error {
		border-color: $color-error-light;
  	background: lighten($color-error, 52%);
	}
}

// CARD
.card {
  background: $color-bg;
  border-radius: 8px;
  // box-shadow: 10px 10px 20px rgba(0,0,0,0.05);
  padding: $spacer;
  border: 2px solid $color-border;
	transition: border-color .3s;
	
  .head {
    display: flex;
    align-items: flex-start;

    .title {
      margin-right: auto;
    }
  }

	&:hover {
		border-color: lighten($color-primary, 25%);
		// border-color: $color-border;
	}

	&.dark {
		background: darken($color-primary, 35%);
		color: rgba(255,255,255,0.7);
	}
}

// Icons
.icon {
  width: 18px;
  fill: $color-primary;
}


// Searchbox
.search {
  position: relative;

  .icon.clear {
    position: absolute;
    right: 0;
    top: 8px;
    height: 18px;
  
    span {
      display: block;
      height: 10px;
      width: 2px;
      left: 8px;
      top: 4px;
      transform: rotate(45deg);
      background: $color-primary;
      position: absolute;
      transition: all .3s;
  
      &:last-child {
        transform: rotate(-45deg);
      }
    }
  }
}


// GRID TABLE
.table {
  display: grid;
  align-items: flex-start;

  .row {
    display: grid;
    grid-template-columns: 5fr 1fr 1fr 1fr 3fr;
    position: relative;
    border-bottom: 1px solid transparent;
    color: $color-text;
		transition: border-color .2s;

    &:nth-child(even) {
      background: rgba(0,0,0,0.01);
    }

    &.no-results {
      grid-template-columns: 1fr !important;
      cursor: auto !important;

      .cell {
        justify-content: flex-start !important;
        text-align: left !important;
      }
    }

    &:first-child {
      user-select: none;
      height: 50px;
      line-height: 50px;
      font-weight: $medium;
      border-bottom: 2px solid lighten($color-primary, 25%);

      .sort {
        width: 10px;
        margin-left: $spacer-sm;
          
        svg {
          width: 10px;
          height: 5px;
          stroke-width: 3;
          fill: none;
          stroke: lighten($color-primary, 25%);
          stroke-linecap: round;
          stroke-linejoin: round;
          transition: all .3s;
          margin-bottom: 1px;

          path {
            transition: all .3s;
          }
        }
      }

      .cell {
        display: flex;
        align-items: center;
        height: 48px;

        &:hover {
          svg {
            stroke: $color-primary;
          }
        }
      }
    
      @media (max-width: 550px) {
        display: none;
      }
    }

    &.desc {
      .sort path {
        d: path("M1,1 L10,9 L19,1");
      }
    }

    // &:last-child {
    //   border-bottom: none;
    // }

    &:not(:first-child) {
      cursor: pointer;

      &:hover {
        border-color: lighten($color-primary, 25%);
        // opacity: 1;
      }
    }

    @media (max-width: 550px) {
      grid-template-columns: 1fr;
      padding: 10px 20px;
    }

    .cell {
      padding: 0 10px;
      height: 58px;
      display: flex;
      align-items: center;

      &.overflow {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        > span {
          text-overflow: ellipsis;
          overflow: hidden;
          // padding-right: 10px;
        }
      }

      &.actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
				text-align: right;

        .edit-delete {
          opacity: 0;
          transition: all .3s;
          display: flex;
          justify-content: flex-end;

          @media (max-width: 500px) {
            opacity: 1;
          }
        }
      }

      a {
        color: $color-yellow;

        &:hover {
          color: $color-yellow;
        }
      }

			&.align-right {
				justify-content: flex-end;
        text-align: right;
			}

      @media (max-width: 550px) {
        padding: 0 !important;
        height: auto;
        line-height: 1.8;
        grid-column-start: 1;
        grid-column-end: 3;

        &.data-title {
          &:before {
            content: attr(data-title);
            display: block;
            padding: 0 5px 0 0;
            color: $color-primary;
          }
        }

        &:last-child {
          grid-column-start: 3;
          grid-column-end: 4;
          grid-row-start: 1;
          grid-row-end: 1;
        }
      }
    }

    &:not(:first-child):hover {
      color: $color-primary;
    }
  }
}

// Tooltips
[aria-label][role~="tooltip"] {
  position: relative;

  &::before,
  &::after {
    transform: translate3d(-50%, 0, 0);
    backface-visibility: hidden;
    will-change: transform;
    opacity: 0;
    pointer-events: none;
    transition: all .3s .2s ease-out;
    position: absolute;
    box-sizing: border-box;
    z-index: 10;
    transform-origin: top;
  }

  &::before {
    content: "";
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 6px solid lighten($color-primary, 50%);
    margin-bottom: 5px;
    transform: translate3d(-50%, 0, 0);
    bottom: 100%;
    left: 50%;
  }

  &::after {
    background: lighten($color-primary, 50%);
    color: darken($color-primary, 15%);
    text-transform: none;
    text-align: center;
    border-radius: 5px;
    content: attr(aria-label);
    font-size: 11px;
    font-weight: 400;
    line-height: 1.4;
    padding: 5px;
    box-sizing: content-box;
    white-space: initial;
    width: 120px;
    margin-bottom: 11px;
    transform: translate3d(-50%, 0, 0);
    bottom: 100%;
    left: 50%;
  }

  &:hover::before,
  &:hover::after,
  &:focus::before,
  &:focus::after {
    opacity: 1;
    pointer-events: auto;
  }
}

[role~="tooltip"]:hover::before {
  transform: translate3d(-50%, -5px, 0);
}

[role~="tooltip"]:hover::after {
  transform: translate3d(-50%, -5px, 0);
}