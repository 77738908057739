
      @import "./assets/vue/styles/_vars.scss";
      @import "./assets/vue/styles/_mixins.scss";
      $color-primary: #765691;
      $color-secondary: #ffce00;
			$color-border: #e6dde9;
			$color-bg: #f8f7f8;
			$color-text: #16051a;
    





































































































































































































































































































































.status {
  font-size: $fontsize;
  vertical-align: super;
  font-weight: $medium;

  &.online {
    color: $color-success;
  }

  &.offline {
    color: $color-error;
  }

  &.maintenance {
    color: $color-warning;
  }
}

.btn-set {  
  .token-input {
    margin-right: $spacer;
  }
}

.services {
  .service {
    border: 2px solid $color-error-light;
    border-radius: 6px;
    padding: $spacer-sm;
    transition: border-color .3s;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .actions {
      height: 18px;

      button {
        width: 18px;
        height: 18px;

        svg {
          fill: $color-primary;
        }
      }
    }

    &:hover {
      border-color: $color-error;
    }

    &.active {
      border-color: $color-success-light;

      &:hover {
        border-color: $color-success;
      }
    }
  }
}

::v-deep {
  .data-info {
    margin-bottom: 0 !important;
  }
}
