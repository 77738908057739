
      @import "./assets/vue/styles/_vars.scss";
      @import "./assets/vue/styles/_mixins.scss";
      $color-primary: #765691;
      $color-secondary: #ffce00;
			$color-border: #e6dde9;
			$color-bg: #f8f7f8;
			$color-text: #16051a;
    


























































































































































































































































































































































































.organisationslist {
  .row {
    grid-template-columns: 1fr 1fr 100px;

    .name {
      .id {
        font-size: $fontsize-sm;
        font-weight: $medium;
        color: lighten($color-primary, 25%);
        margin-right: $spacer-xs;
        flex-shrink: 0;
      }
    }

		.roles {
			span {
				background: lighten($color-primary, 25%);
				padding: $spacer-xs;
				border-radius: 4px;
				font-size: $fontsize-sm;
				color: $color-bg;
				font-weight: 500;
				
				&:not(:last-child) {
					margin-right: $spacer-xs;
				}
			}
		}
  }
}
