html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html, body {
  font-family: "Gotham", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.6;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  color: black;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  margin: 0;
  font-weight: 500;
  line-height: inherit;
  color: #1b1421;
}

h1, .h1 {
  font-size: 32px;
  line-height: 1.4;
}

h2, .h2 {
  font-size: 24px;
}

h3, .h3 {
  font-size: 18px;
}

h4, .h4 {
  font-size: inherit;
}

p {
  font-size: inherit;
  margin-bottom: 2rem;
  line-height: inherit;
}
p:last-of-type {
  margin-bottom: 0;
}

b, strong {
  font-weight: 500;
}

.mx-xs {
  margin-left: 5px;
  margin-right: 5px;
}

.mx-sm {
  margin-left: 10px;
  margin-right: 10px;
}

.mx {
  margin-left: 20px;
  margin-right: 20px;
}

.mx-lg {
  margin-left: 40px;
  margin-right: 40px;
}

.mx-xl {
  margin-left: 80px;
  margin-right: 80px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-none {
  margin-left: 0;
  margin-right: 0;
}

.my-xs {
  margin-top: 5px;
  margin-bottom: 5px;
}

.my-sm {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-lg {
  margin-top: 40px;
  margin-bottom: 40px;
}

.my-xl {
  margin-top: 80px;
  margin-bottom: 80px;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.my-none {
  margin-top: 0;
  margin-bottom: 0;
}

.mt-xs {
  margin-top: 5px;
}

.mt-sm {
  margin-top: 10px;
}

.mt {
  margin-top: 20px;
}

.mt-lg {
  margin-top: 40px;
}

.mt-xl {
  margin-top: 80px;
}

.mt-xxl {
  margin-top: 160px;
}

.mt-auto {
  margin-top: auto;
}

.mt-none {
  margin-top: 0;
}

.mb-xs {
  margin-bottom: 5px;
}

.mb-sm {
  margin-bottom: 10px;
}

.mb {
  margin-bottom: 20px;
}

.mb-lg {
  margin-bottom: 40px;
}

.mb-xl {
  margin-bottom: 80px;
}

.mb-xxl {
  margin-bottom: 160px;
}

.mb-auto {
  margin-bottom: auto;
}

.mb-none {
  margin-bottom: 0;
}

.mr-xs {
  margin-right: 5px;
}

.mr-sm {
  margin-right: 10px;
}

.mr {
  margin-right: 20px;
}

.mr-lg {
  margin-right: 40px;
}

.mr-xl {
  margin-right: 80px;
}

.mr-auto {
  margin-right: auto;
}

.mr-none {
  margin-right: 0;
}

.ml-xs {
  margin-left: 5px;
}

.ml-sm {
  margin-left: 10px;
}

.ml {
  margin-left: 20px;
}

.ml-lg {
  margin-left: 40px;
}

.ml-xl {
  margin-left: 80px;
}

.ml-auto {
  margin-left: auto;
}

.ml-none {
  margin-left: 0;
}

.mb-fluid {
  margin-bottom: 5vw;
}

.mt-fluid {
  margin-top: 5vw;
}

.ml-fluid {
  margin-left: 5vw;
}

.mr-fluid {
  margin-right: 5vw;
}

@media (min-width: 576px) {
  .mx-xs-xs {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mx-xs-sm {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mx_xs {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mx-xs-lg {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mx-xs-xl {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mx-xs-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .mx-xs-none {
    margin-left: 0;
    margin-right: 0;
  }

  .my-xs-xs {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .my-xs-sm {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .my_xs {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .my-xs-lg {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .my-xs-xl {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .my-xs-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .my-xs-none {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mt-xs-xs {
    margin-top: 5px;
  }

  .mt-xs-sm {
    margin-top: 10px;
  }

  .mt_xs {
    margin-top: 20px;
  }

  .mt-xs-lg {
    margin-top: 40px;
  }

  .mt-xs-xl {
    margin-top: 80px;
  }

  .mt-xs-auto {
    margin-top: auto;
  }

  .mt-xs-none {
    margin-top: 0;
  }

  .mb-xs-xs {
    margin-bottom: 5px;
  }

  .mb-xs-sm {
    margin-bottom: 10px;
  }

  .mb_xs {
    margin-bottom: 20px;
  }

  .mb-xs-lg {
    margin-bottom: 40px;
  }

  .mb-xs-xl {
    margin-bottom: 80px;
  }

  .mb-xs-auto {
    margin-bottom: auto;
  }

  .mb-xs-none {
    margin-bottom: 0;
  }

  .mr-xs-xs {
    margin-right: 5px;
  }

  .mr-xs-sm {
    margin-right: 10px;
  }

  .mr_xs {
    margin-right: 20px;
  }

  .mr-xs-lg {
    margin-right: 40px;
  }

  .mr-xs-xl {
    margin-right: 80px;
  }

  .mr-xs-auto {
    margin-right: auto;
  }

  .mr-xs-none {
    margin-right: 0;
  }

  .ml-xs-xs {
    margin-left: 5px;
  }

  .ml-xs-sm {
    margin-left: 10px;
  }

  .ml_xs {
    margin-left: 20px;
  }

  .ml-xs-lg {
    margin-left: 40px;
  }

  .ml-xs-xl {
    margin-left: 80px;
  }

  .ml-xs-auto {
    margin-left: auto;
  }

  .ml-xs-none {
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .mx-sm-xs {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mx-sm-sm {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mx_sm {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mx-sm-lg {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mx-sm-xl {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .mx-sm-none {
    margin-left: 0;
    margin-right: 0;
  }

  .my-sm-xs {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .my-sm-sm {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .my_sm {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .my-sm-lg {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .my-sm-xl {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .my-sm-none {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mt-sm-xs {
    margin-top: 5px;
  }

  .mt-sm-sm {
    margin-top: 10px;
  }

  .mt_sm {
    margin-top: 20px;
  }

  .mt-sm-lg {
    margin-top: 40px;
  }

  .mt-sm-xl {
    margin-top: 80px;
  }

  .mt-sm-auto {
    margin-top: auto;
  }

  .mt-sm-none {
    margin-top: 0;
  }

  .mb-sm-xs {
    margin-bottom: 5px;
  }

  .mb-sm-sm {
    margin-bottom: 10px;
  }

  .mb_sm {
    margin-bottom: 20px;
  }

  .mb-sm-lg {
    margin-bottom: 40px;
  }

  .mb-sm-xl {
    margin-bottom: 80px;
  }

  .mb-sm-auto {
    margin-bottom: auto;
  }

  .mb-sm-none {
    margin-bottom: 0;
  }

  .mr-sm-xs {
    margin-right: 5px;
  }

  .mr-sm-sm {
    margin-right: 10px;
  }

  .mr_sm {
    margin-right: 20px;
  }

  .mr-sm-lg {
    margin-right: 40px;
  }

  .mr-sm-xl {
    margin-right: 80px;
  }

  .mr-sm-auto {
    margin-right: auto;
  }

  .mr-sm-none {
    margin-right: 0;
  }

  .ml-sm-xs {
    margin-left: 5px;
  }

  .ml-sm-sm {
    margin-left: 10px;
  }

  .ml_sm {
    margin-left: 20px;
  }

  .ml-sm-lg {
    margin-left: 40px;
  }

  .ml-sm-xl {
    margin-left: 80px;
  }

  .ml-sm-auto {
    margin-left: auto;
  }

  .ml-sm-none {
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .mx-md-xs {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mx-md-sm {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mx_md {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mx-md-lg {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mx-md-xl {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mx-md-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .mx-md-none {
    margin-left: 0;
    margin-right: 0;
  }

  .my-md-xs {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .my-md-sm {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .my_md {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .my-md-lg {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .my-md-xl {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .my-md-none {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mt-md-xs {
    margin-top: 5px;
  }

  .mt-md-sm {
    margin-top: 10px;
  }

  .mt_md {
    margin-top: 20px;
  }

  .mt-md-lg {
    margin-top: 40px;
  }

  .mt-md-xl {
    margin-top: 80px;
  }

  .mt-md-auto {
    margin-top: auto;
  }

  .mt-md-none {
    margin-top: 0;
  }

  .mb-md-xs {
    margin-bottom: 5px;
  }

  .mb-md-sm {
    margin-bottom: 10px;
  }

  .mb_md {
    margin-bottom: 20px;
  }

  .mb-md-lg {
    margin-bottom: 40px;
  }

  .mb-md-xl {
    margin-bottom: 80px;
  }

  .mb-md-auto {
    margin-bottom: auto;
  }

  .mb-md-none {
    margin-bottom: 0;
  }

  .mr-md-xs {
    margin-right: 5px;
  }

  .mr-md-sm {
    margin-right: 10px;
  }

  .mr_md {
    margin-right: 20px;
  }

  .mr-md-lg {
    margin-right: 40px;
  }

  .mr-md-xl {
    margin-right: 80px;
  }

  .mr-md-auto {
    margin-right: auto;
  }

  .mr-md-none {
    margin-right: 0;
  }

  .ml-md-xs {
    margin-left: 5px;
  }

  .ml-md-sm {
    margin-left: 10px;
  }

  .ml_md {
    margin-left: 20px;
  }

  .ml-md-lg {
    margin-left: 40px;
  }

  .ml-md-xl {
    margin-left: 80px;
  }

  .ml-md-auto {
    margin-left: auto;
  }

  .ml-md-none {
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .mx-lg-xs {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mx-lg-sm {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mx_lg {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mx-lg-lg {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mx-lg-xl {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .mx-lg-none {
    margin-left: 0;
    margin-right: 0;
  }

  .my-lg-xs {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .my-lg-sm {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .my_lg {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .my-lg-lg {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .my-lg-xl {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .my-lg-none {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mt-lg-xs {
    margin-top: 5px;
  }

  .mt-lg-sm {
    margin-top: 10px;
  }

  .mt_lg {
    margin-top: 20px;
  }

  .mt-lg-lg {
    margin-top: 40px;
  }

  .mt-lg-xl {
    margin-top: 80px;
  }

  .mt-lg-auto {
    margin-top: auto;
  }

  .mt-lg-none {
    margin-top: 0;
  }

  .mb-lg-xs {
    margin-bottom: 5px;
  }

  .mb-lg-sm {
    margin-bottom: 10px;
  }

  .mb_lg {
    margin-bottom: 20px;
  }

  .mb-lg-lg {
    margin-bottom: 40px;
  }

  .mb-lg-xl {
    margin-bottom: 80px;
  }

  .mb-lg-auto {
    margin-bottom: auto;
  }

  .mb-lg-none {
    margin-bottom: 0;
  }

  .mr-lg-xs {
    margin-right: 5px;
  }

  .mr-lg-sm {
    margin-right: 10px;
  }

  .mr_lg {
    margin-right: 20px;
  }

  .mr-lg-lg {
    margin-right: 40px;
  }

  .mr-lg-xl {
    margin-right: 80px;
  }

  .mr-lg-auto {
    margin-right: auto;
  }

  .mr-lg-none {
    margin-right: 0;
  }

  .ml-lg-xs {
    margin-left: 5px;
  }

  .ml-lg-sm {
    margin-left: 10px;
  }

  .ml_lg {
    margin-left: 20px;
  }

  .ml-lg-lg {
    margin-left: 40px;
  }

  .ml-lg-xl {
    margin-left: 80px;
  }

  .ml-lg-auto {
    margin-left: auto;
  }

  .ml-lg-none {
    margin-left: 0;
  }
}
.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.data-info {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  flex-wrap: wrap;
}

.vtc {
  width: 100%;
  height: 60px;
  background: #eef4fa;
}
.vtc .stroke {
  stroke-width: 2;
  stroke: #3074b9;
}
.vtc .fill {
  fill: #c5daf0;
}
.vtc .active-line {
  stroke: #f8f7f8;
}
.vtc .point {
  display: none;
  fill: #3074b9;
  stroke: #3074b9;
}
.vtc .point.is-active {
  display: block;
}
.vtc.clicked {
  height: 120px;
}
.vtc.yellow {
  background: white;
}
.vtc.yellow .stroke {
  stroke: #f7e11b;
}
.vtc.yellow .fill {
  fill: #fefbe0;
}
.vtc.yellow .point {
  fill: #f7e11b;
  stroke: #f7e11b;
}
.vtc.orange {
  background: white;
}
.vtc.orange .stroke {
  stroke: #e77e1b;
}
.vtc.orange .fill {
  fill: #fae6d4;
}
.vtc.orange .point {
  fill: #e77e1b;
  stroke: #e77e1b;
}
.vtc.red {
  background: white;
}
.vtc.red .stroke {
  stroke: #c53b3b;
}
.vtc.red .fill {
  fill: #f4d8d8;
}
.vtc.red .point {
  fill: #c53b3b;
  stroke: #c53b3b;
}

.page-head {
  display: flex;
  align-items: center;
}
.page-head .title {
  margin-right: auto;
}
.page-head .title > span {
  font-size: 12px;
  color: #b5a0c7;
  vertical-align: super;
}
.page-head .actions {
  margin-left: 20px;
}

.subhead {
  background: linear-gradient(180deg, #f1f2f3, transparent);
}

legend {
  font-size: 10px;
  color: #a7a7a7;
  margin-top: 10px;
}

.info {
  padding: 20px;
  border: 2px solid #e6dde9;
  background: #f1f1f1;
  border-radius: 4px;
}
.info.error {
  border-color: #f4d8d8;
  background: white;
}

.card {
  background: #f8f7f8;
  border-radius: 8px;
  padding: 20px;
  border: 2px solid #e6dde9;
  transition: border-color 0.3s;
}
.card .head {
  display: flex;
  align-items: flex-start;
}
.card .head .title {
  margin-right: auto;
}
.card:hover {
  border-color: #b5a0c7;
}
.card.dark {
  background: #1b1421;
  color: rgba(255, 255, 255, 0.7);
}

.icon {
  width: 18px;
  fill: #765691;
}

.search {
  position: relative;
}
.search .icon.clear {
  position: absolute;
  right: 0;
  top: 8px;
  height: 18px;
}
.search .icon.clear span {
  display: block;
  height: 10px;
  width: 2px;
  left: 8px;
  top: 4px;
  transform: rotate(45deg);
  background: #765691;
  position: absolute;
  transition: all 0.3s;
}
.search .icon.clear span:last-child {
  transform: rotate(-45deg);
}

.table {
  display: grid;
  align-items: flex-start;
}
.table .row {
  display: grid;
  grid-template-columns: 5fr 1fr 1fr 1fr 3fr;
  position: relative;
  border-bottom: 1px solid transparent;
  color: #16051a;
  transition: border-color 0.2s;
}
.table .row:nth-child(even) {
  background: rgba(0, 0, 0, 0.01);
}
.table .row.no-results {
  grid-template-columns: 1fr !important;
  cursor: auto !important;
}
.table .row.no-results .cell {
  justify-content: flex-start !important;
  text-align: left !important;
}
.table .row:first-child {
  user-select: none;
  height: 50px;
  line-height: 50px;
  font-weight: 500;
  border-bottom: 2px solid #b5a0c7;
}
.table .row:first-child .sort {
  width: 10px;
  margin-left: 10px;
}
.table .row:first-child .sort svg {
  width: 10px;
  height: 5px;
  stroke-width: 3;
  fill: none;
  stroke: #b5a0c7;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: all 0.3s;
  margin-bottom: 1px;
}
.table .row:first-child .sort svg path {
  transition: all 0.3s;
}
.table .row:first-child .cell {
  display: flex;
  align-items: center;
  height: 48px;
}
.table .row:first-child .cell:hover svg {
  stroke: #765691;
}
@media (max-width: 550px) {
  .table .row:first-child {
    display: none;
  }
}
.table .row.desc .sort path {
  d: path("M1,1 L10,9 L19,1");
}
.table .row:not(:first-child) {
  cursor: pointer;
}
.table .row:not(:first-child):hover {
  border-color: #b5a0c7;
}
@media (max-width: 550px) {
  .table .row {
    grid-template-columns: 1fr;
    padding: 10px 20px;
  }
}
.table .row .cell {
  padding: 0 10px;
  height: 58px;
  display: flex;
  align-items: center;
}
.table .row .cell.overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table .row .cell.overflow > span {
  text-overflow: ellipsis;
  overflow: hidden;
}
.table .row .cell.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
}
.table .row .cell.actions .edit-delete {
  opacity: 0;
  transition: all 0.3s;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 500px) {
  .table .row .cell.actions .edit-delete {
    opacity: 1;
  }
}
.table .row .cell a {
  color: #f7e11b;
}
.table .row .cell a:hover {
  color: #f7e11b;
}
.table .row .cell.align-right {
  justify-content: flex-end;
  text-align: right;
}
@media (max-width: 550px) {
  .table .row .cell {
    padding: 0 !important;
    height: auto;
    line-height: 1.8;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .table .row .cell.data-title:before {
    content: attr(data-title);
    display: block;
    padding: 0 5px 0 0;
    color: #765691;
  }
  .table .row .cell:last-child {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1;
  }
}
.table .row:not(:first-child):hover {
  color: #765691;
}

[aria-label][role~=tooltip] {
  position: relative;
}
[aria-label][role~=tooltip]::before, [aria-label][role~=tooltip]::after {
  transform: translate3d(-50%, 0, 0);
  backface-visibility: hidden;
  will-change: transform;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s 0.2s ease-out;
  position: absolute;
  box-sizing: border-box;
  z-index: 10;
  transform-origin: top;
}
[aria-label][role~=tooltip]::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 6px solid #f3f0f6;
  margin-bottom: 5px;
  transform: translate3d(-50%, 0, 0);
  bottom: 100%;
  left: 50%;
}
[aria-label][role~=tooltip]::after {
  background: #f3f0f6;
  color: #4f3a61;
  text-transform: none;
  text-align: center;
  border-radius: 5px;
  content: attr(aria-label);
  font-size: 11px;
  font-weight: 400;
  line-height: 1.4;
  padding: 5px;
  box-sizing: content-box;
  white-space: initial;
  width: 120px;
  margin-bottom: 11px;
  transform: translate3d(-50%, 0, 0);
  bottom: 100%;
  left: 50%;
}
[aria-label][role~=tooltip]:hover::before, [aria-label][role~=tooltip]:hover::after, [aria-label][role~=tooltip]:focus::before, [aria-label][role~=tooltip]:focus::after {
  opacity: 1;
  pointer-events: auto;
}

[role~=tooltip]:hover::before {
  transform: translate3d(-50%, -5px, 0);
}

[role~=tooltip]:hover::after {
  transform: translate3d(-50%, -5px, 0);
}

.container,
.container-full {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 768px) {
  .container,
.container-full {
    padding-right: 40px;
    padding-left: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media (min-width: 1200px) {
  .container {
    padding-right: 80px;
    padding-left: 80px;
  }
}
.container .grid,
.container-full .grid {
  margin-right: -20px;
  margin-left: -20px;
}

.grid {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 auto;
  position: relative;
  *letter-spacing: normal;
  list-style-type: none;
}

.grid::before, .grid::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: normal;
  word-spacing: normal;
  white-space: normal;
}

[class*=col-] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: normal;
  word-spacing: normal;
  white-space: normal;
  position: relative;
  width: 100%;
  vertical-align: top;
  padding: 20px;
  display: inline-block;
  *display: inline;
  zoom: 1;
}
[class*=col-].fieldset {
  padding-top: 0;
  padding-bottom: 0;
}

[class*=col-]::before, [class*=col-]::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: normal;
  word-spacing: normal;
  white-space: normal;
}

[class*=col-] .grid {
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: -20px;
}

.col-12 {
  width: 100%;
  *width: 99.9%;
}

.col-11 {
  width: 91.66666667%;
  *width: 91.56666667%;
}

.col-10 {
  width: 83.33333333%;
  *width: 83.23333333%;
}

.col-9 {
  width: 75%;
  *width: 74.9%;
}

.col-8 {
  width: 66.66666667%;
  *width: 66.56666667%;
}

.col-7 {
  width: 58.33333333%;
  *width: 58.23333333%;
}

.col-6 {
  width: 50%;
  *width: 49.9%;
}

.col-5 {
  width: 41.66666667%;
  *width: 41.56666667%;
}

.col-4 {
  width: 33.33333333%;
  *width: 33.23333333%;
}

.col-3 {
  width: 25%;
  *width: 24.9%;
}

.col-2 {
  width: 16.66666667%;
  *width: 16.56666667%;
}

.col-1 {
  width: 8.33333333%;
  *width: 8.23333333%;
}

@media (min-width: 576px) {
  .col-xs-12 {
    width: 100%;
    *width: 99.9%;
  }

  .col-xs-11 {
    width: 91.66666667%;
    *width: 91.56666667%;
  }

  .col-xs-10 {
    width: 83.33333333%;
    *width: 83.23333333%;
  }

  .col-xs-9 {
    width: 75%;
    *width: 74.9%;
  }

  .col-xs-8 {
    width: 66.66666667%;
    *width: 66.56666667%;
  }

  .col-xs-7 {
    width: 58.33333333%;
    *width: 58.23333333%;
  }

  .col-xs-6 {
    width: 50%;
    *width: 49.9%;
  }

  .col-xs-5 {
    width: 41.66666667%;
    *width: 41.56666667%;
  }

  .col-xs-4 {
    width: 33.33333333%;
    *width: 33.23333333%;
  }

  .col-xs-3 {
    width: 25%;
    *width: 24.9%;
  }

  .col-xs-2 {
    width: 16.66666667%;
    *width: 16.56666667%;
  }

  .col-xs-1 {
    width: 8.33333333%;
    *width: 8.23333333%;
  }
}
@media (min-width: 768px) {
  .col-sm-12 {
    width: 100%;
    *width: 99.9%;
  }

  .col-sm-11 {
    width: 91.66666667%;
    *width: 91.56666667%;
  }

  .col-sm-10 {
    width: 83.33333333%;
    *width: 83.23333333%;
  }

  .col-sm-9 {
    width: 75%;
    *width: 74.9%;
  }

  .col-sm-8 {
    width: 66.66666667%;
    *width: 66.56666667%;
  }

  .col-sm-7 {
    width: 58.33333333%;
    *width: 58.23333333%;
  }

  .col-sm-6 {
    width: 50%;
    *width: 49.9%;
  }

  .col-sm-5 {
    width: 41.66666667%;
    *width: 41.56666667%;
  }

  .col-sm-4 {
    width: 33.33333333%;
    *width: 33.23333333%;
  }

  .col-sm-3 {
    width: 25%;
    *width: 24.9%;
  }

  .col-sm-2 {
    width: 16.66666667%;
    *width: 16.56666667%;
  }

  .col-sm-1 {
    width: 8.33333333%;
    *width: 8.23333333%;
  }
}
@media (min-width: 992px) {
  .col-md-12 {
    width: 100%;
    *width: 99.9%;
  }

  .col-md-11 {
    width: 91.66666667%;
    *width: 91.56666667%;
  }

  .col-md-10 {
    width: 83.33333333%;
    *width: 83.23333333%;
  }

  .col-md-9 {
    width: 75%;
    *width: 74.9%;
  }

  .col-md-8 {
    width: 66.66666667%;
    *width: 66.56666667%;
  }

  .col-md-7 {
    width: 58.33333333%;
    *width: 58.23333333%;
  }

  .col-md-6 {
    width: 50%;
    *width: 49.9%;
  }

  .col-md-5 {
    width: 41.66666667%;
    *width: 41.56666667%;
  }

  .col-md-4 {
    width: 33.33333333%;
    *width: 33.23333333%;
  }

  .col-md-3 {
    width: 25%;
    *width: 24.9%;
  }

  .col-md-2 {
    width: 16.66666667%;
    *width: 16.56666667%;
  }

  .col-md-1 {
    width: 8.33333333%;
    *width: 8.23333333%;
  }
}
@media (min-width: 1200px) {
  .col-lg-12 {
    width: 100%;
    *width: 99.9%;
  }

  .col-lg-11 {
    width: 91.66666667%;
    *width: 91.56666667%;
  }

  .col-lg-10 {
    width: 83.33333333%;
    *width: 83.23333333%;
  }

  .col-lg-9 {
    width: 75%;
    *width: 74.9%;
  }

  .col-lg-8 {
    width: 66.66666667%;
    *width: 66.56666667%;
  }

  .col-lg-7 {
    width: 58.33333333%;
    *width: 58.23333333%;
  }

  .col-lg-6 {
    width: 50%;
    *width: 49.9%;
  }

  .col-lg-5 {
    width: 41.66666667%;
    *width: 41.56666667%;
  }

  .col-lg-4 {
    width: 33.33333333%;
    *width: 33.23333333%;
  }

  .col-lg-3 {
    width: 25%;
    *width: 24.9%;
  }

  .col-lg-2 {
    width: 16.66666667%;
    *width: 16.56666667%;
  }

  .col-lg-1 {
    width: 8.33333333%;
    *width: 8.23333333%;
  }
}
@media (min-width: 1600px) {
  .col-xlg-12 {
    width: 100%;
    *width: 99.9%;
  }

  .col-xlg-11 {
    width: 91.66666667%;
    *width: 91.56666667%;
  }

  .col-xlg-10 {
    width: 83.33333333%;
    *width: 83.23333333%;
  }

  .col-xlg-9 {
    width: 75%;
    *width: 74.9%;
  }

  .col-xlg-8 {
    width: 66.66666667%;
    *width: 66.56666667%;
  }

  .col-xlg-7 {
    width: 58.33333333%;
    *width: 58.23333333%;
  }

  .col-xlg-6 {
    width: 50%;
    *width: 49.9%;
  }

  .col-xlg-5 {
    width: 41.66666667%;
    *width: 41.56666667%;
  }

  .col-xlg-4 {
    width: 33.33333333%;
    *width: 33.23333333%;
  }

  .col-xlg-3 {
    width: 25%;
    *width: 24.9%;
  }

  .col-xlg-2 {
    width: 16.66666667%;
    *width: 16.56666667%;
  }

  .col-xlg-1 {
    width: 8.33333333%;
    *width: 8.23333333%;
  }
}
.align-start {
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.align-end {
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}

.align-end [class*=col-] {
  vertical-align: bottom;
}

.align-center {
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.align-center [class*=col-] {
  vertical-align: middle;
}

.align-baseline {
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
}

.align-content-start {
  -ms-flex-line-pack: start;
  -webkit-align-content: flex-start;
  align-content: flex-start;
}

.align-content-end {
  -ms-flex-line-pack: end;
  -webkit-align-content: flex-end;
  align-content: flex-end;
}

.align-content-end [class*=col-] {
  vertical-align: bottom;
}

.align-content-center {
  -ms-flex-line-pack: center;
  -webkit-align-content: center;
  align-content: center;
}

.align-content-space-between {
  -ms-flex-line-pack: justify;
  -webkit-align-content: space-between;
  align-content: space-between;
}

.align-content-space-around {
  -ms-flex-line-pack: distribute;
  -webkit-align-content: space-around;
  align-content: space-around;
}

.align-self-stretch {
  -ms-flex-item-align: stretch;
  -webkit-align-self: stretch;
  align-self: stretch;
}

.align-self-start {
  -ms-flex-item-align: start;
  -webkit-align-self: flex-start;
  align-self: flex-start;
}

.align-self-end {
  -ms-flex-item-align: end;
  -webkit-align-self: flex-end;
  align-self: flex-end;
  vertical-align: bottom;
}

.align-self-center {
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center;
  vertical-align: middle;
}

.align-self-baseline {
  -ms-flex-item-align: baseline;
  -webkit-align-self: baseline;
  align-self: baseline;
  vertical-align: baseline;
}

.justify-start {
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.justify-end {
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.justify-center {
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.justify-space-between {
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

/* .justify-space-between.grid {
  text-align: justify;
  -moz-text-align-last: justify;
  text-align-last: justify;
} */
.justify-space-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

.grid-bleed [class*=col-] {
  padding: 0;
}

.col-grid {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.col-grid.direction-row {
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.col-bleed {
  padding: 0;
}

.col-bleed-x {
  padding: 20px 0;
}

.col-bleed-y {
  padding: 0 20px;
}

@media (max-width: 575px) {
  .hidden-xxs {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .hidden-xs {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1599px) {
  .hidden-lg {
    display: none;
  }
}
@media (min-width: 1600px) {
  .hidden-xlg {
    display: none;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #f8f7f8 inset !important;
}

.fieldset {
  position: relative;
  margin-bottom: 20px;
}
.fieldset.fieldset-error input {
  border-color: #c53b3b;
}

input[type=text],
input[type=password],
input[type=email],
input[type=number],
textarea {
  font-size: 12px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  border-bottom: 2px solid #e6dde9;
  transition: color 0.3s, border 0.3s;
  background: #f8f7f8;
  border-radius: 0;
  height: 34px;
  appearance: none;
  text-align: inherit;
  font-weight: 400;
  color: inherit;
  font-family: inherit;
}
input[type=text]:-webkit-autofill::first-line,
input[type=password]:-webkit-autofill::first-line,
input[type=email]:-webkit-autofill::first-line,
input[type=number]:-webkit-autofill::first-line,
textarea:-webkit-autofill::first-line {
  font-family: inherit;
  font-size: 12px;
}

input[type=radio] {
  display: inline-block;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: #765691;
}

::placeholder {
  color: #a7a7a7;
}

.fieldsetgroup {
  display: flex;
}
.fieldsetgroup .fieldset {
  margin-right: 20px;
  flex: 1;
}
.fieldsetgroup .fieldset:last-child {
  margin-right: 0;
}

.password-instructions {
  margin-top: 10px;
}
.password-instructions span {
  display: inline-block;
  font-size: 11px;
  background: #c53b3b;
  border-radius: 5px;
  padding: 2px 5px;
  margin-bottom: 5px;
}
.password-instructions span:not(:first-child) {
  color: #f8f7f8;
}
.password-instructions span.text {
  background: transparent;
}

label:not(.switch) {
  font-size: 10px;
  font-weight: 500;
  line-height: 1;
}

.radio label,
.checkbox label {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  user-select: none;
  line-height: 18px;
}
.radio label input,
.checkbox label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.radio label input:checked ~ .checkmark,
.checkbox label input:checked ~ .checkmark {
  border-color: #b5a0c7;
}
.radio label input:checked ~ .checkmark:after,
.checkbox label input:checked ~ .checkmark:after {
  display: block;
}
.radio label .checkmark,
.checkbox label .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 2px solid #e6dde9;
  border-radius: 100%;
  transition: all 0.3s;
}
.radio label .checkmark:after,
.checkbox label .checkmark:after {
  top: 4px;
  left: 4px;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: #765691;
  content: "";
  position: absolute;
  display: none;
}
.radio label:hover input ~ .checkmark,
.checkbox label:hover input ~ .checkmark {
  border-color: #a7a7a7;
}

.checkbox:not(:last-child) {
  margin-bottom: 10px;
}

label.switch {
  position: relative;
  display: block;
  width: 100%;
  height: 26px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 0;
}
label.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
label.switch .slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40px;
  background-color: transparent;
  transition: 0.4s;
  border-radius: 14px;
  border: 2px solid #e6dde9;
}
label.switch .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 2px;
  background-color: #a7a7a7;
  transition: 0.4s;
  border-radius: 100%;
}

input:checked + .slider {
  border-color: #765691;
}

input:checked + .slider:before {
  background-color: #765691;
}

input:not(:checked):hover + .slider:before {
  transform: translateX(2px);
}

input:checked:hover + .slider:before {
  transform: translateX(12px);
}

input:checked + .slider:before {
  transform: translateX(14px);
}

.v-select {
  position: relative;
  font-family: inherit;
}

@-webkit-keyframes vSelectSpinner {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
@keyframes vSelectSpinner {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
.vs__fade-enter-active,
.vs__fade-leave-active {
  pointer-events: none;
  transition: opacity 0.15s;
}

.vs__fade-enter,
.vs__fade-leave-to {
  opacity: 0;
}

.vs--disabled .vs__clear,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__open-indicator,
.vs--disabled .vs__search,
.vs--disabled .vs__selected {
  cursor: not-allowed;
  background-color: transparent;
  border-color: #f3f0f6;
  fill: #f3f0f6;
}

.vs__dropdown-toggle {
  appearance: none;
  display: flex;
  padding: 0;
  background: #f8f7f8;
  border-bottom: 2px solid #b5a0c7;
  white-space: normal;
  min-height: 34px;
}

.vs__selected-options {
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
  flex-wrap: wrap;
  position: relative;
  min-width: 0;
}

.vs--single .vs__selected-options {
  flex-wrap: nowrap;
}

.vs__actions {
  display: flex;
  align-items: center;
  padding: 5px 0 0 5px;
  cursor: pointer;
}

.vs--searchable .vs__dropdown-toggle {
  cursor: text;
}

.vs--unsearchable .vs__dropdown-toggle {
  cursor: pointer;
}

.vs--open:not(.drop-up) .vs__dropdown-toggle {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.vs--open.drop-up .vs__dropdown-toggle {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.vs__open-indicator {
  width: 14px;
  fill: #b5a0c7;
  transform: scale(1);
  transition: transform 0.15s cubic-bezier(1, -0.115, 0.975, 0.855);
  transition-timing-function: cubic-bezier(1, -0.115, 0.975, 0.855);
}

.vs--open .vs__open-indicator {
  transform: rotate(180deg) scale(1);
}

.vs--loading .vs__open-indicator {
  opacity: 0;
}

.vs__clear {
  fill: #c53b3b;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  margin-right: 10px;
  min-width: auto;
  width: 10px;
}

.fieldset-error .vs__dropdown-toggle,
.fieldset-error .vs__dropdown-menu {
  border-color: #c53b3b !important;
}

.vs__dropdown-menu {
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: calc(100% - 2px);
  left: 0;
  z-index: 1000;
  padding: 0;
  margin: 0;
  width: 100%;
  max-height: 350px;
  min-width: 80px;
  overflow-y: auto;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.15);
  text-align: left;
  list-style: none;
  background: #765691;
}

[data-popper-placement=top] {
  border-top-style: solid;
  border-bottom-style: none;
  border-top: 2px solid #b5a0c7;
  box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.15);
}

.vs__no-options {
  padding: 20px;
  color: #f8f7f8;
}
.vs__no-options a {
  font-weight: 500;
  color: #ffce00;
}

.vs__dropdown-option {
  line-height: 1.6;
  padding: 10px;
  clear: both;
  color: #f8f7f8;
  white-space: nowrap;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}
.vs__dropdown-option em {
  margin-left: auto;
  font-style: inherit;
  color: #b5a0c7;
  font-size: 9px;
  font-weight: 500;
  line-height: 20px;
}
.vs__dropdown-option em.circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #c53b3b;
}
.vs__dropdown-option em.red {
  color: #c53b3b;
}
.vs__dropdown-option em.green {
  color: #60a74b;
}
.vs__dropdown-option em.yellow {
  color: #e77e1b;
}

.vs__dropdown-option:hover {
  cursor: pointer;
}

.vs__dropdown-option--highlight {
  background: #4f3a61;
  color: #f8f7f8;
}

.vs__dropdown-option--disabled {
  background: inherit;
  color: #f3f0f6 !important;
  cursor: not-allowed !important;
}

.vs__dropdown-option--disabled:hover {
  cursor: inherit;
}

.vs__selected {
  display: flex;
  align-items: center;
  z-index: 0;
  line-height: 1.6;
  color: #1b1421;
}

.vs__deselect {
  display: inline-flex;
  appearance: none;
  margin-left: 5px;
  padding: 0;
  border: 0;
  cursor: pointer;
  background: none;
  fill: #c53b3b;
  text-shadow: 0 1px 0 #fff;
}

.vs--single .vs__selected {
  background-color: transparent;
  border-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  padding: 5px 5px 0 0;
}

.vs--single.vs--open .vs__selected {
  position: absolute;
  opacity: 0.4;
  width: 100%;
  padding-right: 20px;
}

.vs--single.vs--searching .vs__selected {
  display: none;
}

.vs__search::-webkit-search-cancel-button,
.vs__search::-ms-clear,
.vs__search::-webkit-search-decoration,
.vs__search::-webkit-search-results-button,
.vs__search::-webkit-search-results-decoration {
  display: none;
}

.vs__search,
.vs__search:focus {
  appearance: none;
  border: none;
  outline: none;
  padding: 0;
  background: none;
  box-shadow: none;
  width: 0;
  max-width: 100%;
  flex-grow: 1;
  z-index: 1;
  font-size: 12px;
}

.vs--unsearchable .vs__search {
  opacity: 1;
}

.vs--unsearchable:not(.vs--disabled) .vs__search:hover {
  cursor: pointer;
}

.vs--single.vs--searching:not(.vs--open):not(.vs--loading) .vs__search {
  opacity: 0.2;
}

.vs__spinner {
  align-self: center;
  opacity: 0;
  font-size: 5px;
  text-indent: -9999em;
  overflow: hidden;
  border: 0.9em solid rgba(100, 100, 100, 0.1);
  border-left-color: rgba(60, 60, 60, 0.45);
  transform: translateZ(0);
  animation: vSelectSpinner 1.1s linear infinite;
  transition: opacity 0.1s;
}

.vs__spinner,
.vs__spinner:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}

.vs--loading .vs__spinner {
  opacity: 1;
}

.v-select:not(.vs--single) .vs__selected {
  background: #f3f0f6;
  margin: 0 5px 5px 0;
  border-radius: 4px;
  padding: 5px;
  font-size: 10px;
  line-height: 1;
  align-self: flex-end;
}

@font-face {
  font-family: "Gotham";
  src: url("../fonts/SVN-Gotham-Book.woff2") format("woff2"), url("../fonts/SVN-Gotham-Book.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Gotham";
  src: url("../fonts/SVN-Gotham-Regular.woff2") format("woff2"), url("../fonts/SVN-Gotham-Regular.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
*, *::after, *::before {
  box-sizing: border-box;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  height: 100%;
  overflow: hidden;
  overscroll-behavior: none;
}

html {
  background: #f8f7f8 !important;
  color: #16051a;
}

img, svg {
  width: 100%;
  height: auto;
}

.app {
  height: 100%;
  display: flex;
  overflow: hidden;
}
.app > .content {
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  overscroll-behavior: none;
  flex-grow: 1;
}
.app > .content > * {
  min-height: 100%;
  width: 100%;
}

a {
  color: #765691;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.3s;
  will-change: color;
}
@media (hover: hover) {
  a:hover {
    color: #ffce00;
  }
}
a.link {
  color: #1b1421;
  font-weight: 500;
  border-bottom: 2px solid #765691;
  padding: 3px 0 5px 0;
}
@media (hover: hover) {
  a.link:hover {
    color: #765691;
  }
}

button {
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s;
  will-change: auto;
  font-size: inherit;
}
button:focus {
  outline: 0;
}
button:disabled {
  cursor: not-allowed;
}
button.submitting {
  background: #765691;
}
button.disabled {
  opacity: 0.5;
}
@media (hover: hover) {
  button.disabled:hover {
    background: #765691;
  }
}

.btn {
  display: block;
  padding: 6px 20px 10px 20px;
  background: #765691;
  color: #f8f7f8;
  border: none;
  cursor: pointer;
  font-weight: 500;
  border-radius: 4px;
  font-family: inherit;
  transition: all 0.3s;
  line-height: 14px;
  min-height: 0;
  text-align: center;
  border: 2px solid #765691;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  user-select: none;
}
.btn > .plus {
  font-size: 20px;
  line-height: 0;
  display: inline-block;
  vertical-align: sub;
}
.btn.red {
  background: #c53b3b;
  border-color: #c53b3b;
}
@media (hover: hover) {
  .btn.red:hover {
    background: #9e2f2f;
    border-color: #9e2f2f;
  }
}
@media (hover: hover) {
  .btn:hover {
    color: #f8f7f8;
    background: #4f3a61;
    border-color: #4f3a61;
  }
}
.btn.btn-secondary {
  background: #f8f7f8;
  color: #765691;
  border: 2px solid #765691;
}
@media (hover: hover) {
  .btn.btn-secondary:hover {
    color: #1b1421;
    border-color: #1b1421;
  }
}
.btn.small {
  font-size: 10px;
  padding: 3px 4px 4px 4px;
}
.btn.block {
  width: 100%;
}

.btn-set {
  display: flex;
  align-items: center;
}
.btn-set > *:not(:last-child) {
  margin-right: 10px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}