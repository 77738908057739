
      @import "./assets/vue/styles/_vars.scss";
      @import "./assets/vue/styles/_mixins.scss";
      $color-primary: #765691;
      $color-secondary: #ffce00;
			$color-border: #e6dde9;
			$color-bg: #f8f7f8;
			$color-text: #16051a;
    







































































































































































.status {
  font-size: 12px;
  display: flex;

  > span {
    padding-bottom: $spacer-xs;
    margin-left: $spacer;
    border-bottom: 2px solid $color-error;
    transition: all .3s;

    &.active,
    &.online {
      border-bottom: 2px solid $color-success;
    }
  }
}

.access-code {
	font-weight: $medium;
	span {
		margin-right: $spacer-xs;
		color: $color-gray;
	}
}
