
      @import "./assets/vue/styles/_vars.scss";
      @import "./assets/vue/styles/_mixins.scss";
      $color-primary: #765691;
      $color-secondary: #ffce00;
			$color-border: #e6dde9;
			$color-bg: #f8f7f8;
			$color-text: #16051a;
    

















































































































































































































































.box-title {
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
}

.box-item{
  // display: flex;
  margin-top:5px;
}

.box-name {
  width: 70%;
}

.domain-title {
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
}

.domain-container{
  margin-top: 10px;
  margin-left: 3px;
}

.domain-item{
  display: flex;
}

.domain-name {
  width: 70%;
}

