
      @import "./assets/vue/styles/_vars.scss";
      @import "./assets/vue/styles/_mixins.scss";
      $color-primary: #765691;
      $color-secondary: #ffce00;
			$color-border: #e6dde9;
			$color-bg: #f8f7f8;
			$color-text: #16051a;
    










































































































































































































































































.product-info {
  display: flex;
  flex-wrap: wrap;
  font-weight: $medium;

  li {
    &:not(:last-child) {
      margin-right: $spacer;
    }

    span {
      margin-right: $spacer-xs;
      color: $color-gray;
    }
  }
}
