
      @import "./assets/vue/styles/_vars.scss";
      @import "./assets/vue/styles/_mixins.scss";
      $color-primary: #765691;
      $color-secondary: #ffce00;
			$color-border: #e6dde9;
			$color-bg: #f8f7f8;
			$color-text: #16051a;
    












































































































































































































































































































































































































































































































































































































































































.required-notification{
  color : gray
}

.register {
  min-height: 100%;
  max-width: 500px;
  display: flex;
  align-items: center;

  .auth-form {
    text-align: center;
  }
}
