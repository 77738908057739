
      @import "./assets/vue/styles/_vars.scss";
      @import "./assets/vue/styles/_mixins.scss";
      $color-primary: #765691;
      $color-secondary: #ffce00;
			$color-border: #e6dde9;
			$color-bg: #f8f7f8;
			$color-text: #16051a;
    



































































































































































































































































































































































































.title {
	h4 {
		color: lighten($color-primary, 25%);
	}
}

.credit {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	line-height: 1;

	.label {
		color: lighten($color-primary, 25%);
		font-weight: $medium;
		margin-bottom: $spacer-xs;
	}

	.value {
		font-weight: $regular;

		&.minus {
			color: $color-error;
		}
	}
}
